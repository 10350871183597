<template>

  <div id="chec" ref="checc" style="height: 1800px; background-color: rgb(229, 229, 234)">
    <!-- 导入试剂库模板dialog -->
    <div class="muban-dialog-div">
      <el-dialog :visible.sync="dialogVisible" width="50%" show-close>
        <div slot="title" style="background: #504da6; color: white">
          <div style="padding: 15px 20px">{{ "导入患者基础信息" }}</div>
        </div>
        <div>
          <el-upload :action="updataUrl" class="upload-demo" ref="upload" :multiple="true" show-file-list name="file"
                     :on-remove="handleRemove" :on-change="(file, fileList) => {
          handleUploadChange(file, fileList, this.dialog_Index);
        }
        " :on-exceed="handleExceed" :file-list="fileList" :auto-upload="false">
            <el-button icon="el-icon-document" size="small" type="primary" slot="trigger" style="
                width: 120px;
                height: 35px;
                background-color: #504da6;
                border: none;
              " :loading="fullscreenLoading">选取文件
            </el-button>
            <el-button size="small" icon="el-icon-upload" type="primary" style="
                width: 120px;
                height: 35px;
                margin-left: 20px;
                background-color: #504da6;
                border: none;
              " @click="submitUploadUser" :loading="fullscreenLoading" :disabled="scdisabled">开始上传
            </el-button>
            <!--              <el-button size="small" icon="el-icon-s-data" type="primary" v-if="dialog_Index==1||dialog_Index==2"-->
            <!--                         style="width: 120px; height: 35px; margin-left: 20px;background-color: #504DA6;border: none;"-->
            <!--                         @click="dataAnalysis" :loading="fullscreenLoading" :disabled="!analysisObj">数据分析</el-button>-->
            <div slot="tip" class="el-upload__tip" v-if="dialog_Index == 0 || dialog_Index == 1">
              上传文件只能为excel文件，且为csv格式
            </div>
            <div slot="tip" class="el-upload__tip" v-else>
              上传文件应为文本文件，且为txt格式
              <!-- 1.14 ,新加FGM-->
            </div>
          </el-upload>
        </div>
        <!-- <div style="margin-top: 20px">
            <el-progress :text-inside="true" :stroke-width="17" :percentage="this.percentage"></el-progress>
          </div> -->

        <div style="height: 500px; margin-top: 15px" v-if="opeateType == 'upData'">
          <el-collapse accordion>
            <el-collapse-item title="模板数据录入说明" style="font-size: 16px">
              <p v-if="dialog_Index == 0">
                1. 文件非空校验<br/>
                2. 文件格式校验 (csv格式校验)<br/>
                3. 标题列名称校验(标题名为:id name gender age type birthday) <br/>
                4. 数据ID校验 (ID不能以0开头且ID已存在无法添加，示例：111)<br/>
                5.
                模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败<br/>
                <!-- 1.
                  模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败。<br />
                  2.
                  文件名必须以br开头, 以cgm结尾。<br />
                  3. 上传文件只能为excel文件，且为csv格式。<br /> -->
              </p>
              <p v-else-if="dialog_Index == 1">
                1. 文件非空校验<br/>
                2. 文件名校验 ()<br/>
                3. 文件格式校验 (csv格式校验)<br/>
                4. 标题列名称校验 (名称与排列顺序)<br/>
                5. 数据表重名校验 (表名已存在无法添加)<br/>
                6.
                模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败<br/>
              </p>
              <p v-else>
                1. 文件非空校验<br/>
                2. 文件格式校验 (txt格式校验)<br/>
                <!-- 文本格式校验,1.14 -->
                3. 标题列名称校验 (名称与排列顺序)<br/>
                4. 数据表重名校验 (表名已存在无法添加)<br/>
                5.模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败<br/>
              </p>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div v-else>
          <div id="Pdf" class="printeBox">
            <h1 align="middle">数据质量分析报告</h1>
            <el-table highlight-current-row v-loading="loading" :data="analysisObj.data"
                      style="margin-top: 15px; width: 100%" align="center" @selection-change="handleSelectionChange">
              <!-- <el-table-column type="selection" width="55" /> -->
              <el-table-column prop="FaultType" label="故障类型" align="center"/>
              <el-table-column prop="Count" label="故障个数" align="center"/>
              <el-table-column prop="Percentage" label="正确率(%)" align="center"/>
            </el-table>
            <div style="margin-top: 20px; border: 1px solid #000000"></div>
            <div style="margin: 15px">
              <div style="color: #000000; text-align: left">数据文件信息:</div>
              <div style="color: #000000; text-align: left; margin-top: 5px">
                {{ analysisObj.fileName }}
              </div>
            </div>
          </div>
          <div class="reprotButton">
            <button class="tool-ButtonStyle" @click="back">返回</button>
            <button class="tool-ButtonStyle" @click="printPdf">下载PDF</button>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="dialog_diy">
      <el-dialog :visible.sync="dialogVisible1" width="50%" show-close>
        <div slot="title" style="background: #504da6; color: white">
          <div style="padding: 15px 20px">{{ "导入患者CGM信息" }}</div>
        </div>
        <div>
          <el-upload :action="updataUrl" class="upload-demo" ref="upload" :multiple="true" show-file-list name="file"
                     :on-remove="handleRemove" :on-change="(file, fileList) => {
          handleUploadChange(file, fileList, this.dialog_Index);
        }
        " :on-exceed="handleExceed" :file-list="fileList" :auto-upload="false">
            <el-button icon="el-icon-document" size="small" type="primary" slot="trigger" style="
                width: 120px;
                height: 35px;
                background-color: #504da6;
                border: none;
              " :loading="fullscreenLoading">选取文件
            </el-button>
            <el-button size="small" icon="el-icon-upload" type="primary" style="
                width: 120px;
                height: 35px;
                margin-left: 20px;
                background-color: #504da6;
                border: none;
              " @click="submitUpload" :loading="fullscreenLoading" :disabled="scdisabled">开始上传
            </el-button>
            <!--              <el-button size="small" icon="el-icon-s-data" type="primary" v-if="dialog_Index==1||dialog_Index==2"-->
            <!--                         style="width: 120px; height: 35px; margin-left: 20px;background-color: #504DA6;border: none;"-->
            <!--                         @click="dataAnalysis" :loading="fullscreenLoading" :disabled="!analysisObj">数据分析</el-button>-->
            <div slot="tip" class="el-upload__tip" v-if="dialog_Index == 0 || dialog_Index == 1">
              上传文件只能为excel或txt文件，且excel文件为csv格式
            </div>
            <div slot="tip" class="el-upload__tip" v-else>
              上传文件应为文本文件，且为txt格式
              <!-- 1.14 ,新加FGM-->
            </div>
          </el-upload>
        </div>
        <!-- <div style="margin-top: 20px">
            <el-progress :text-inside="true" :stroke-width="17" :percentage="this.percentage"></el-progress>
          </div> -->

        <div style="height: 500px; margin-top: 15px" v-if="opeateType == 'upData'">
          <el-collapse accordion>
            <el-collapse-item title="模板数据录入说明" style="font-size: 16px">
              <p v-if="dialog_Index == 0">
                1. 文件非空校验<br/>
                2. 文件格式校验 (csv格式校验)<br/>
                3. 标题列名称校验 (名称与排列顺序)<br/>
                4. 数据ID重复校验 (ID已存在无法添加)<br/>
                5.
                模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败<br/>
                <!-- 1.
                  模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败。<br />
                  2.
                  文件名必须以br开头, 以cgm结尾。<br />
                  3. 上传文件只能为excel文件，且为csv格式。<br /> -->
              </p>
              <p v-else-if="dialog_Index == 1">
                1. 文件非空校验<br/>
                2. 文件名校验 (以agp开头, 以患者id结尾，示例：agp123)<br/>
                3. 文件格式校验 (csv格式校验，时间格式为yyyy/m/d h:mm，示例：2027/5/5 20:57 )<br/>
                4. 标题列名称校验 (名称与排列顺序，标题名：time mg mmol)<br/>
                5. 数据表重名校验 (表名已存在无法添加)<br/>
                6.
                模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败<br/>
              </p>
              <p v-else>
                1. 文件非空校验<br/>
                2. 文件格式校验 (txt格式校验)<br/>
                <!-- 文本格式校验,1.14 -->
                3. 标题列名称校验 (名称与排列顺序)<br/>
                4. 数据表重名校验 (表名已存在无法添加)<br/>
                5.模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败<br/>
              </p>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div v-else>
          <div id="Pdf" class="printeBox">
            <h1 align="middle">数据质量分析报告</h1>
            <el-table highlight-current-row v-loading="loading" :data="analysisObj.data"
                      style="margin-top: 15px; width: 100%" align="center" @selection-change="handleSelectionChange">
              <!-- <el-table-column type="selection" width="55" /> -->
              <el-table-column prop="FaultType" label="故障类型" align="center"/>
              <el-table-column prop="Count" label="故障个数" align="center"/>
              <el-table-column prop="Percentage" label="正确率(%)" align="center"/>
            </el-table>
            <div style="margin-top: 20px; border: 1px solid #000000"></div>
            <div style="margin: 15px">
              <div style="color: #000000; text-align: left">数据文件信息:</div>
              <div style="color: #000000; text-align: left; margin-top: 5px">
                {{ analysisObj.fileName }}
              </div>
            </div>
          </div>
          <div class="reprotButton">
            <button class="tool-ButtonStyle" @click="back">返回</button>
            <button class="tool-ButtonStyle" @click="printPdf">下载PDF</button>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="cgmCheckBox">
      <div class="cgmCheckBoxLeft">
        <div class="tool-style">
          <button class="tool-ButtonStyle" style="margin-left: 60px;" @click="openPopup(null, 0)">
            导入患者基础信息
          </button>
          <button class="tool-ButtonStyle" style="margin-left: 40px;" @click="openPopup(null, 1)">
            导入患者CGM信息
          </button>
        </div>
        <div class="personalMessageBanner">
          <div class="personalMessageItem">
            <span class="itemTitle" style="margin-left: 30px;">姓名 : </span>{{ maskedName }}
            <span class="itemTitle" style="margin-left: 30px;">ID : </span>{{ TWOid }}
            <span class="itemTitle" style="margin-left: 30px;">年龄 : </span>{{ TWOage }}
            <span class="itemTitle" style="margin-left: 30px;">性别 : </span>{{ TWOgender }}
            <span class="itemTitle" style="margin-left: 30px;">类型 : </span>{{ TWOtype }}
            <span class="itemTitle" style="margin-left: 20px;">数据间隔: </span>
            <select v-model="CGMinterval" @change="changeInterval" style="margin-left: 10px;">
              <option value="5">5分钟</option>
              <option value="15">15分钟</option>
            </select>
          </div>

          <!--                  <div class="personalMessageItem">-->
          <!--                    <input type="button" class="buttonItem" @click="changeLine" value="上传患者数据" style="margin-left: 20px;"/>-->
          <!--                  </div>-->
        </div>
        <div id="AGP" style="
            font-weight: bolder;
            font-size: 18px;
            margin-top: 1px;
            margin-left: 60px;
            margin-bottom: 1px;
          ">
          AGP报告：持续葡萄糖监测
        </div>
        <div class="cgmResult">
          <div class="dataQualityBanner">
            <h2 class="dataQualityTitle">数据质量情况</h2>

            <div class="line-container">
              <div class="hengline" :style="{ width: 100 + 'px' }"></div>
              <div class="value">极高：{{ TWOTAR2 }}%</div>
              <div class="shuline" :style="{ width: 100 + 'px' }"></div>
              <div class="value1">高：{{ TWOTAR1 }}%</div>
              <div class="shuline1" :style="{ width: 100 + 'px' }"></div>
              <div class="hengline1" :style="{ width: 100 + 'px' }"></div>
              <div class="value2">{{ TWOTAR }}%</div>
              <div class="hengline2" :style="{ width: 200 + 'px' }"></div>
              <div class="value3" style=margin-right:30px;>目标：{{ TWOTIR }}%</div>
              <div class="hengline3" :style="{ width: 100 + 'px' }"></div>
              <div class="value4">低：{{ TWOTBR1 }}%</div>
              <div class="hengline4" :style="{ width: 100 + 'px' }"></div>
              <div class="value5">极低：{{ TWOTBR2 }}%</div>
              <div class="shuline2" :style="{ width: 100 + 'px' }"></div>
              <div class="hengline1" :style="{ width: 100 + 'px' }"></div>
              <div class="value2">{{ TWOTBR }}%</div>
            </div>
            <!--            <div class="value250" style="margin-right: 60px">250</div>-->
            <!--            <div class="value180">180</div>-->

            <!--            <div class="wenzi" style="font-weight: bolder">目标范围</div>-->
            <div class="value1801" style="font-weight: bolder; color: rgb(72, 220, 116)">

            </div>
            <div class="value70" style="font-weight: bolder; color: rgb(72, 220, 116)">

            </div>
            <template v-if="userid && startday2">
              <echart5 :userid="this.userid" :startday="this.startday2" :key="startday2"
                       style="height: 430px;"></echart5>
            </template>

          </div>
          <div class="cgmUseAdviceBanner">
            <h2 class="cgmUseAdviceTitle">数据分析</h2>
            <div class="tableBox">
              <table class="dataQualityAssessTable1">
                <el-table :data="tableData1" style="width: 100%; height: 95%" :key="tableIndexValue">
                  <el-table-column prop="date" label="基本信息" width="180">
                  </el-table-column>
                  <el-table-column prop="name" label="" width="240">
                  </el-table-column>
                </el-table>
              </table>
              <table class="dataQualityAssessTable">
                <el-table :data="tableData" stripe style="width: 100%" :key="tableIndexValue">
                  <el-table-column prop="title" label="葡萄糖指标" width="180" max-height="50">
                  </el-table-column>
                  <el-table-column prop="number" width="180"></el-table-column>
                </el-table>
              </table>
            </div>
          </div>
        </div>

        <div class="echarts1" style="margin-top: 10%">
          <div style="
              padding: 10px 5px 0px 20px;
              height: 35px;
              font-size: 16px;
              margin-left: 20px;
              background-color: rgb(219, 223, 227);
            ">
            动态葡萄糖图谱（AGP）
          </div>
          <div style="padding: 0px 5px 0px 20px;
                      margin-left: 30px;">
            AGP是报告期内葡萄糖值的汇总，显示的中位数(50%)和其他百分是在一天内发生的
          </div>
          <template>
            <component
                v-if="userid && startday2 && minute"
                :is="currentComponent"
                style="height: 140%;width:100% ;margin-left: 10px;margin-top: 0.1%;"
                :userid="userid"
                :startday="startday2"
                :minute="minute"
                :key="startday2"
            >
            </component>
          </template>
        </div>
        <div class="echarts2" style="margin-top: 5%">
          <div style="
              padding: 10px 5px 0px 20px;
              height: 35px;
              font-size: 16px;
              margin-left: 20px;
              background-color: rgb(219, 223, 227);
            ">
            每日葡萄糖概括
          </div>
          <div style="padding: 0px 5px 0px 20px;
                margin-left: 30px;">
            每一个每日概括两午夜之间的血糖数据
          </div>
          <div class="container2">
            <div class="color-block2"></div>
            <span class="text2">数据异常标注</span>
            <div  class="color-block3"></div>
            <span class="text2">插值处理</span>
          </div>
          <div style="padding: 0px 5px 0px 35px; margin-top: 2%;
           margin-left: 10px;
           font-size: 12px">
            mmol/L
          </div>
          <template>
            <component
                v-if="userid && startday2 && minute"
                :is="currentComponent2"
                style="height: 28%; width: 100%; margin-left: 5px;"
                :userid="userid"
                :startday="startday2"
                :minute="minute"
                :key="startday2"
            >
            </component>
          </template>

        </div>
      </div>

      <div class="cgmCheckBoxRight">
        <div class="patientSelectBanner">
          <div class="patientSelectTitle" style="width: 25%">患者:</div>
          <el-select v-model="SelectedValue" filterable style="width: 80%" placeholder="请选择" @change="optionChange"
                     :loading="loadingPatientUser">
            <!-- <el-option label="请选择" value="" /> -->
            <el-option
                v-for="item in maskedPatientList"
                :key="item.id"
                :label="item.maskedName + ' 【' + item.id + '】'"
                :value="item.id">
              <span style="float: left">{{ item.maskedName }}</span>
              <!--                            <span style="float: right; color: #8492a6; font-size: 13px ;margin-right: 10px;width: 20px;">{{-->
              <!--                                item.gender }}-->
              <!--                            </span>-->
              <span style="
                  float: right;
                  color: #8492a6;
                  font-size: 13px;
                  margin-right: 20px;
                ">{{ item.id }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="dataQueryBanner">
          <h2 class="dataQueryTitle">日期选择</h2>
          <div class="buttonList">
            <!-- <input type="button" class="buttonItem" @click="updataCourseByHand" value="刷新病程" /> -->
            <input type="button" class="buttonItem" @click="changeLine" value="查询"/>
          </div>
          <template v-if="showDate3">
            <v-date-picker class="dataQueryCalendar" mode="date" v-model="date3" :key="'test'"/>
          </template>
        </div>
        <div class="doctorMessageBanner">
          <div class="checkButtonBox">
            <input type="button" class="checkButton" value="导出对比报告" @click="pdfClick2"/>
            <input type="button" class="checkButton" value="导出分析报告" @click="pdfClick"/>
          </div>
          <h2 class="doctorMessageTitle">
            查看医师<span>工号-{{ this.$store.state.doctorID }}</span>
          </h2>
          <h2 class="doctorMessageTitle">
            检测时间<span>{{ currentTime }}</span>
          </h2>
        </div>
      </div>
    </div>
    <!-- <echart2 style="height: 20%;width:80% ;margin-left: 15%;"/> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "axios";
// import { request } from '../network/request.js'
import request from "@/utils/request";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {GetUserInfor} from "../api/user";
//import echart3 from '../components/echart3.vue'
import echart2 from "../components/echart2.vue";
import echart4 from "../components/echart4.vue";
import echart5 from "../components/echart5.vue";
import echart6 from "../components/echart6.vue";
import echart7 from "../components/echart7.vue";
import {
  GetAllPatientUserList,
  GetPatientUserCGMTimeData,
  GetCGMyichangjianceForlinechart,
  Getyichangtimestamp,
} from "@/api/patient.js";

import {
  getTime,
  getAge,
  timeParas,
  getFromateTime,
  nameParas,
  retainDecimal,
} from "../utils/index.js";
import {
  diseaseTypeOption,
  SexOption,
  isDrink,
  MonthInfo,
} from "../common/resource.js";

// import circleProgressBar from '@/src/components/circleprogressbar.vue';
export default {
  name: "page2",
  data() {
    return {
      analysisObj: {data: [], fileName: ""},
      updataUrl: "",
      opeateType: "upData",
      loading: false,
      ttt1: "",
      currentTime: "",
      tableIndexValue: 1,
      tableData: [
        {
          title: "平均葡萄糖",
          number: "",
        },
        {
          title: "葡萄糖管理指标(GMI)",
          number: "",
        },
        {
          title: "葡萄糖变异性",
          number: "",
        },
      ],
      tableData1: [
        {
          date: "生日: ",
          name: "",
        },
        {
          date: "佩戴开始日期: ",
          name: "",
        },
        {
          date: "CGM活跃时间：",
          name: "",
        },
        ,
      ],
      minute: "5",
      userid: "",
      TWOid: "",
      TWOname: "",
      TWOgender: "",
      TWOntype: "",
      TWOage: "",

      TWOGMI: "",
      TWOCV: "",
      TWOPer95: [],
      startday: "",
      startday2: "",
      TWOTBR1: "",
      TWOTBR2: "",
      TWOTAR1: "",
      TWOTAR2: "",
      TWOTIR: "",
      TWOMEDIAN: "",
      TWOTAR: "",
      TWOTBR: "",
      Per95: [],

      first: true,

      globalLoading: null,
      showDate3: true,
      date3: new Date(),
      dateRange: {
        // start: new Date(2020, 0, 1),
        //       end: new Date(2020, 0, 5)
        start: undefined, //
        end: undefined,
      },
      dates: new Date("2023-11-01"),

      disabledDates: [6, 8, 15],
      selectedPatientObj: {},

      patientUserId: "",
      patientList: [],
      SelectedValue: "",
      patientdata: {},
      startdays: [],
      loadingPatientUser: false,

      visibleDataTime: [],
      dockerObj: null,
      calendar: null,
      // dates: {
      // 	start: new Date(2019, 0, 14),
      // 	end: new Date(2019, 0, 18)
      // },
      userNameParase: "",

      MSG: "",
      SD: "",
      GMI: "",
      CV: "",
      TAR: "",
      TBR: "",
      HypoCount: "",
      TIR: "",

      CGMinterval: '5',  // 默认设置为5分钟

      dayMSG: "",
      daySD: "",
      dayGMI: "",
      dayCV: "",
      dayTAR: "",
      dayTBR: "",
      dayHypoCount: "",
      dayTIR: "",
      nightMSG: "",
      nightSD: "",
      nightGMI: "",
      nightCV: "",
      nightTAR: "",
      nightTBR: "",
      nightHypoCount: "",
      nightTIR: "",

      dialogVisible: false,
      dialogVisible1: false,
      fullscreenLoading: false,
      fileList: [], //导入文件，文件存储位置
      dialog_Index: 0,
      scdisabled: true, //导入模板表单选取文件按钮开关

      remID: "",
      remback: false,
      bl_Id: "",
      brName: "",
      brXb: "",
      type: "",
      csnyr: "",
      qzrq: "",
      spantime: "12",
      ymd1: "",
      ymd2: "",
      hms1: "",
      hms2: "",
      begintime: "",
      endtime: "",
      brID: "20001",
      doctorID: "",
      // 2022.12.10
      // phone: '',
      val: 24,
      // val: 12,
      // 2022.11.30王昕卓修改血糖值时间段
      activeclass: ["changebtn", "atclick", "changebtn"],
      // activeclass: ['atclick', 'changebtn', 'changebtn'],
      dataQuality: [0, "--", "--"],
      suggestion: ["Please wait..."],
      myVar01: "",
      myVar02: "",
      ysID: "",
      ysName: "",
      percentage: 10,
      date: "",
      params: {
        Id: 20001,
        age: 16,
        name: "wangxiaoming",
        bc: "5年2个月",
        hblx: "1型糖尿病",
      },
    };
  },
  computed: {
    maskedName() {
      if (this.TWOname.length > 1) {
        let surname = this.TWOname[0];
        let masked = surname + '*'.repeat(this.TWOname.length - 1);
        return masked;
      }
      return this.TWOname;
    },
    maskedPatientList() {
      return this.patientList.map(item => {
        let maskedName;
        if (item.name.length > 1) {
          let surname = item.name[0];
          maskedName = surname + '*'.repeat(item.name.length - 1);
        } else {
          maskedName = item.name;
        }
        return {
          ...item,
          maskedName
        };
      });
    },
    currentComponent() {
      // 根据 'value' 的值来切换组件
      return this.minute === '5' ? 'echart4' : 'echart6'
    },
    currentComponent2() {
      // 根据 'value' 的值来切换组件
      return this.minute === '5' ? 'echart2' : 'echart7'
    }
  },
  components: {
    // circleProgressBar
    // echart3,
    echart2,
    echart4,
    echart5,
    echart6,
    echart7,
  },
  beforeCreate() {
    // this.getUserID();
    // this.getinfos();
  },

  async created() {
    //this.getMidCompute2();

    // this.getMidCompute();

    this.getCurrentTime();

    this.dockerObj = this.$store.state.user.userObj;
    this.globalLoading = this.$loading({
      lock: true,
      text: "数据加载中...",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.1)",
    });

    this.loadingPatientUser = true;

    // 2022.11.18王昕卓修改接口形式,更换新接口
    this.globalLoading.close();
    this.loadingPatientUser = false;

    // 2022.12.10 aftercreate mounted
    // this.phone = this.$store.state.phone;
    this.doctorID = this.$store.state.doctorID;

    await this.initAllUser()

    await axios
        .get("http://js.aps.ink:8090/getUserInfo", {
          // 传递的参数
          params: {
            id: this.userid,
          },
          // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
        })
        .then((res) => {
          // 如果状态为400,说明登录失败
          if (res.data.status === 400) {
            // 登录失败提示
          } else {
            this.patientdata = res.data.info;
            this.startdays = res.data.startdaysList;
            if (this.startdays.length) {
              this.startday2 = this.startdays[0] + "T00:00:00";
              this.showDate3 = false
              this.date3 = this.startdays[0];
              this.$nextTick(() => {
                this.showDate3 = true
              })
            } else {
              this.setStartday2();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

    this.getUserID();
  },

  mounted() {
    // console.log('mounted');
    var myChart = echarts.init(document.getElementById("main"));
    this.showTime();
    this.getUserMessage();
    this.getTwoWeek();


    // this.getMidCompute();

    // this.layuiRender();
  },

  watch: {
    // 如果 `patientID` 发生改变，这个函数就会运行
    brID: function (newVal, oldVal) {
      this.dataInitialization(); //加载折线图
    },
  },
  beforeDestroy() {
    if (this.globalLoading) {
      this.globalLoading.close();
    }
  },
  methods: {
    capturePageAndExportToPDF() {
      // 使用html2canvas截取整个页面
      html2canvas(this.$el, {
        allowTaint: true,
        useCORS: true,
      }).then(canvas => {
        const contentWidth = canvas.width;
        const contentHeight = canvas.height;
        const pageHeight = contentWidth / 592.28 * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        const imgWidth = 595.28;
        const imgHeight = 592.28 / contentWidth * contentHeight;
        const pageData = canvas.toDataURL('image/jpeg', 1.0);
        const PDF = new jsPDF('', 'pt', 'a4');

        // 当内容不超过PDF一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            // 避免添加空白页
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }

        // PDF.save('page-to-pdf.pdf');
        return pdf.output('datauristring');
      });
    },

    //验证文件,新加txt格式校验,lhh,1.14
    handleUploadChange(file, fileList, isFGMdata) {
      this.opeateType = "upData";
      if (isFGMdata == 1) {
        if (!/\.(txt|TXT|csv|CSV)$/.test(file.name)) {
          this.$notify.error({
            title: "错误",
            message: "上传文件应为excel文件，且为csv格式或文本文件,且为txt格式", //txt校验,lhh,1.14
          });
          this.fileList = [];
          this.file = "";
          return false;
        }
      } else {
        // if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        if (!/\.(csv|CSV)$/.test(file.name)) {
          this.$notify.error({
            title: "错误",
            message: "上传文件只能为excel文件，且为csv格式",
          });
          this.fileList = [];
          this.file = "";
          return false;
        }
      }
      this.scdisabled = false;
      this.Percentage = 0;

      this.fileList = fileList;
      this.file = file.raw;
      this.filename = file.name;
    },
    openPopup(e, type) {
      if (e) {
        this.selectedObj = JSON.parse(JSON.stringify(e));
      }
      this.dialog_Index = type; //type=2,FGMdata,lhh,1.14
      this.file = "";
      this.fileList = [];
      this.analysisDisabled = true;
      this.scdisabled = true; //导入模板表单选取文件按钮开关
      this.fullscreenLoading = false;
      this.analysisObj = {
        data: [],
        fileName: "",
      };

      if (type < 1) {
        this.dialogVisible = true;
      } else {
        this.dialogVisible1 = true;
      }
    },
    getCurrentTime() {
      const now = new Date();
      this.currentTime =
          now.toLocaleDateString() + "时间" + now.toLocaleTimeString();
      // 或者使用其他格式化选项
      // this.currentTime = now.toLocaleString();
      // this.currentTime = now.toISOString();
    },
    onSelected(date) {
      // console.log("Selected date: ", date);
    },
    //删除当前文件
    handleRemove(row) {
      let index = this.fileList.findIndex((item) => {
        return item.uid == row.uid;
      });
      if (index >= 0) {
        this.fileList.splice(index, 1);
      }
      if (this.fileList.length <= 0) {
        this.scdisabled = true;
      }
    },
    changeInterval() {
      if (this.CGMinterval === '5') {
        // 如果选择的是5分钟，设置minute为5
        this.minute = 5;
      } else if (this.CGMinterval === '15') {
        // 如果选择的是15分钟，设置minute为15
        this.minute = 15;
      }
    },
    async submitUpload() {
      // lhh,1.14
      this.opeateType = "upData";
      this.analysisObj = {
        data: [],
        fileName: "",
      };
      // this.$refs.upload.submit();

      if (this.fileList.length <= 0) {
        this.$notify.error({
          title: "错误",
          message: "上传文件不能为空",
        });
        return false;
      } else {
        this.fullscreenLoading = true;
        const formData = new FormData();
        let fileRawList = this.fileList.map((item) => {
          // map() 方法返回一个新数组，数组中的元素为原始数组元素调用函数处理后的值。
          // map() 方法按照原始数组元素顺序依次处理元素。
          return item.raw;
          // String.raw() 是一个模板字符串的标签函数,用来获取一个模板字符串的原始字符串
        });
        //注意上传多文件时用此方法创建参数
        // if (fileRawList.length > 0) {
        // 	fileRawList.forEach(item => {  //forEach方法用于调用数组的每个元素，并将元素传递给回调函数。
        // 		formData.append("multipartFiles", item);  //type类型为file
        // 	})
        // }
        // console.log("hhhhhhhhhhh",formData.getAll("multipartFiles"))
        // console.log("什么玩意这是",formData)
        // formData.forEach((value, key) => {
        //      console.log(key,value);
        // })      //lhh

        let opeateSuccess = false;
        let opeateMsg = "";
        if (this.dialog_Index == 1) {
          // const {
          // 	success,
          // 	msg,
          // 	// errorColNumber
          // } = await BatchImportPatientUserData(formData);   lhh改
          if (fileRawList.length > 0) {
            fileRawList.forEach((item) => {
              //forEach方法用于调用数组的每个元素，并将元素传递给回调函数。
              formData.append("multipartFile", item); //type类型为file
            });
          }
          const {success, message} = await request({
            //url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/basic-information/insertbasicinfofromcsv',
            // url:'http://js.aps.ink:8090/insertbasicinfofromcsv',
            url: "http://js.aps.ink:8090/insertbasicinfofromcsv",
            method: "post",
            body: {multipartFile: formData},
            headers: {"Content-Type": "multipart/form-data"},
            data: formData,
            params: {multipartFile: formData},
          });
          opeateSuccess = success;
          opeateMsg = message;
        } else {
          // const {
          // 	success,
          // 	msg,
          // 	data2,
          // 	data
          // 	// errorColNumber
          // } = await ImportPatientUserCgmData(formData);
          if (fileRawList.length > 0) {
            fileRawList.forEach((item) => {
              //forEach方法用于调用数组的每个元素，并将元素传递给回调函数。
              formData.append("multipartFiles", item); //type类型为file
            });
          }
          let selecturl = ""; // CGM数据和FGM数据接口判断,lhh ,1.14
          if (this.dialog_Index == 0) {
            selecturl =
                "http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/basic-information/getMulFilebyCsv";
          } else {
            selecturl =
                "http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/basic-information/getMulFileByTxt";
          }
          const {success, message, data} = await request({
            url: selecturl,
            method: "post",
            data: formData,
            // params:{doctorPhone:this.docPhone}   // lhh,1.14
            params: {doctorID: this.$store.state.doctorID},
          });
          // console.log("返回数据",data.res)       //lhh改
          // if (data.res) {
          // 	this.analysisObj.fileName = data2;
          // }
          let datalist = [];
          if (data.res && data.res.FaultType && data.res.FaultType.length > 0) {
            //lhh改
            for (let i = 0; i < data.res.FaultType.length; i++) {
              let obj = {
                FaultType: data.res.FaultType[i],
                Count: data.res.Count[i],
                Percentage: data.res.Percentage[i]
                    ? retainDecimal(data.res.Percentage[i] * 100, 2) + "%"
                    : "0%",
              };

              datalist.push(obj);
            }
          }
          this.analysisObj.data = datalist;
          opeateSuccess = success;
          opeateMsg = message;
        }
        this.fullscreenLoading = false;
        if (opeateSuccess) {
          this.Percentage = 100;

          this.$alert(`${opeateMsg}`, `导入成功`, {
            confirmButtonText: "确定",
            type: "success",
          });
          this.fileList = [];
          this.scdisabled = true;
        } else {
          this.Percentage = 0;

          this.$alert(`${opeateMsg}`, "成功", {
            confirmButtonText: "确定",
            type: "danger",
          });
          this.fileList = [];
          this.scdisabled = true;
        }
      }
    },

    async submitUploadUser() {
      // lhh,1.14
      this.opeateType = "upData";
      this.analysisObj = {
        data: [],
        fileName: "",
      };
      // this.$refs.upload.submit();

      if (this.fileList.length <= 0) {
        this.$notify.error({
          title: "错误",
          message: "上传文件不能为空",
        });
        return false;
      } else {
        this.fullscreenLoading = true;
        const formData = new FormData();
        let fileRawList = this.fileList.map((item) => {
          // map() 方法返回一个新数组，数组中的元素为原始数组元素调用函数处理后的值。
          // map() 方法按照原始数组元素顺序依次处理元素。
          return item.raw;
          // String.raw() 是一个模板字符串的标签函数,用来获取一个模板字符串的原始字符串
        });
        //注意上传多文件时用此方法创建参数
        // if (fileRawList.length > 0) {
        // 	fileRawList.forEach(item => {  //forEach方法用于调用数组的每个元素，并将元素传递给回调函数。
        // 		formData.append("multipartFiles", item);  //type类型为file
        // 	})
        // }
        // console.log("hhhhhhhhhhh",formData.getAll("multipartFiles"))
        // console.log("什么玩意这是",formData)
        // formData.forEach((value, key) => {
        //      console.log(key,value);
        // })      //lhh

        let opeateSuccess = false;
        let opeateMsg = "";
        if (this.dialog_Index == 0) {
          // const {
          // 	success,
          // 	msg,
          // 	// errorColNumber
          // } = await BatchImportPatientUserData(formData);   lhh改
          if (fileRawList.length > 0) {
            fileRawList.forEach((item) => {
              //forEach方法用于调用数组的每个元素，并将元素传递给回调函数。
              formData.append("multipartFile", item); //type类型为file
            });
          }
          const {success, message, data} = await request({
            //url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/basic-information/insertbasicinfofromcsv',
            //url:'http://js.aps.ink:8090/insertbasicinfofromcsv',
            url: "http://js.aps.ink:8090/insertbasicinfofromusercsv",
            method: "post",
            body: {multipartFile: formData},
            headers: {"Content-Type": "multipart/form-data"},
            data: formData,
            params: {multipartFile: formData},
          });
          opeateSuccess = success;
          opeateMsg = message;
          this.TWOname = data.name;
          this.TWOid = data.id;
          this.TWOtype = data.type;
          this.TWOage = data.age;
          if (data.gender == 1) {
            this.TWOgender = "男";
          } else {
            this.TWOgender = "女";
          }
          this.tableData1[0].name = data.birthday;
        } else {
          // const {
          // 	success,
          // 	msg,
          // 	data2,
          // 	data
          // 	// errorColNumber
          // } = await ImportPatientUserCgmData(formData);
          if (fileRawList.length > 0) {
            fileRawList.forEach((item) => {
              //forEach方法用于调用数组的每个元素，并将元素传递给回调函数。
              formData.append("multipartFiles", item); //type类型为file
            });
          }
          let selecturl = ""; // CGM数据和FGM数据接口判断,lhh ,1.14
          if (this.dialog_Index == 1) {
            selecturl =
                "http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/basic-information/getMulFilebyCsv";
          } else {
            selecturl =
                "http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/basic-information/getMulFileByTxt";
          }
          const {success, message, data} = await request({
            url: selecturl,
            method: "post",
            data: formData,
            // params:{doctorPhone:this.docPhone}   // lhh,1.14
            params: {doctorID: this.$store.state.doctorID},
          });
          // console.log("返回数据",data.res)       //lhh改
          // if (data.res) {
          // 	this.analysisObj.fileName = data2;
          // }
          let datalist = [];
          if (data.res && data.res.FaultType && data.res.FaultType.length > 0) {
            //lhh改
            for (let i = 0; i < data.res.FaultType.length; i++) {
              let obj = {
                FaultType: data.res.FaultType[i],
                Count: data.res.Count[i],
                Percentage: data.res.Percentage[i]
                    ? retainDecimal(data.res.Percentage[i] * 100, 2) + "%"
                    : "0%",
              };

              datalist.push(obj);
            }
          }
          this.analysisObj.data = datalist;
          opeateSuccess = success;
          opeateMsg = message;
        }
        this.fullscreenLoading = false;
        if (opeateSuccess) {
          this.Percentage = 100;

          this.$alert(`${opeateMsg}`, `导入成功`, {
            confirmButtonText: "确定",
            type: "success",
          });
          this.fileList = [];
          this.scdisabled = true;
        } else {
          this.Percentage = 0;

          this.$alert(`${opeateMsg}`, "成功", {
            confirmButtonText: "确定",
            type: "danger",
          });
          this.fileList = [];
          this.scdisabled = true;
        }
      }
    },
    //验证选择文件数量  上传文件数量限制如,multiple:limit="3",lhh,1.14
    handleExceed() {
      this.$notify.error({
        title: "错误",
        message: "校验错误,超出上传文件数量限制",
      });
    },

    // 初始化换则基本信息视图
    initUserBaseView() {
      const [currentInfo] = this.patientList.filter(
          (e) => e.id === this.SelectedValue
      );
      this.TWOid = currentInfo.id;
      this.TWOname = currentInfo.name;
      this.TWOtype = currentInfo.type;
      this.TWOage = currentInfo.age;
      this.TWOgender = currentInfo.gender;
      this.tableData1[0].name = currentInfo.birthday;
    },

    // 初始化患者下拉列表

    // 获取所有用户信息
    async initAllUser(cb) {
      return new Promise((resolve, reject) => {

        axios
            .get("http://js.aps.ink:8090/selectUsers")
            .then((res) => {
              if (res.status === 200) {
                this.patientList = res.data.infos;
                // 设置默认值
                this.SelectedValue = this.patientList[0].id;
                this.userid = this.patientList[0].id;

                // 登录成功提示
                this.TWOid = res.data.infos[0].id; // 登录成功提示
                resolve()
              }
            })
            .catch((error) => {
              console.log(error);
              reject()
            });
      });
    },
    getMidComputeByCyan() {
      axios
          .get("http://js.aps.ink:8090/getMidCompute", {
            // 传递的参数
            params: {
              id: this.userid,
              startday: this.startday2,
            },
            // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
          })
          .then((res) => {
            // 如果状态为400,说明登录失败
            if (res.data.status === 400) {
              // 登录失败提示
            } else {
              // 登录成功提示
              this.TWOTBR1 = parseFloat(res.data.tbr1percent.toFixed(2));
              this.TWOTBR2 = parseFloat(res.data.tbr2percent.toFixed(2));
              this.TWOTAR1 = parseFloat(res.data.tar1percent.toFixed(2));
              this.TWOTAR2 = parseFloat(res.data.tar2percent.toFixed(2));
              this.TWOTIR = parseFloat(res.data.tirpercent.toFixed(2));
              this.TWOCV = parseFloat(res.data.tirpercent.toFixed(2));
              this.TWOGMI = parseFloat(res.data.gmi.toFixed(2));
              // 对于相加的情况，需要先进行相加操作，然后再格式化
              this.TWOTBR = parseFloat((res.data.tbr2percent + res.data.tbr1percent).toFixed(2));
              this.TWOTAR = parseFloat((res.data.tar2percent + res.data.tar1percent).toFixed(2));
              this.TWOMEDIAN = parseFloat(res.data.median.toFixed(2));

              this.tableData[0].number = res.data.median;
              this.tableData[1].number = res.data.gmi + "%";
              this.tableData[2].number = res.data.tarpercent + "%";
              // console.log(this.tableData[0].name);

              const date = new Date(this.startday2);
              const year = date.getFullYear();
              const month = ("0" + (date.getMonth() + 1)).slice(-2);
              const day = ("0" + date.getDate()).slice(-2);

              const formattedDate = `${year}-${month}-${day}` + "开始";
              this.tableData1[2].name = res.data.cgmactive * 100 + "%";
              this.tableData1[1].name = formattedDate;
              this.tableIndexValue = Math.random();
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getUserID() {

      this.initUserBaseView()

      this.getMidComputeByCyan();
    },

    getUserMessage() {
      if (this.SelectedValue) {
        axios
            .get("http://js.aps.ink:8090/getUserInfo", {
              // 传递的参数
              params: {
                id: this.SelectedValue,
              },
              // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
            })
            .then((res) => {
              console.log("获取用户信息的res", res);
              // 如果状态为400,说明登录失败
              if (res.data.status === 400) {
                // 登录失败提示
                this.$message({
                  showClose: true,
                  message: "登录失败,请检查账号和密码！",
                  type: "error",
                  duration: 2000,
                  center: true,
                });
              } else {
                // 登录成功提示
              }
            })
            .catch((error) => {
              console.log("进入报错", error);
            });
      }
    },

    getTwoWeek() {
      axios
          .get("http://js.aps.ink:8090/selectbgFromOneDay", {
            // 传递的参数
            params: {
              id: this.SelectedValue,
              startday: this.startday2,
            },
            // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
          })
          .then((res) => {
            // 如果状态为400,说明登录失败
            if (res.data.status === 400) {
              // 登录失败提示
              this.$message({
                showClose: true,
                message: "登录失败,请检查账号和密码！",
                type: "error",
                duration: 2000,
                center: true,
              });
            } else {
              // 登录成功提示
              console.log(res);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getMidCompute() {
      axios
          .get("http://js.aps.ink:8090/getMidCompute", {
            // 传递的参数
            params: {
              id: this.TWOid,
            },
            // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
          })
          .then((res) => {
            // 如果状态为400,说明登录失败
            if (res.data.status === 400) {
              // 登录失败提示
              this.$message({
                showClose: true,
                message: "登录失败,请检查账号和密码！",
                type: "error",
                duration: 2000,
                center: true,
              });
            } else {
              // 登录成功提示
              // console.log(res);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },

    handlebackstate() {
      this.$store.commit("backstate");
    },
    handlerembrID(brID) {
      this.$store.commit("remcurrentID", brID);
      return this.$store.state.remcurrentID;
    },
    handlestatereset() {
      this.$store.commit("statereset");
    },
    handleIDreset() {
      this.$store.commit("IDreset");
    },
    changeLine() {
      // this.dynamicChart(this.val);
      // console.log("Selected date: ", this.date3);
      const date = new Date(this.date3);
      // console.log('date', date)
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);

      const formattedDate = `${year}-${month}-${day}`;
      // console.log(formattedDate);
      this.startday2 = formattedDate + "T00:00:00";
      this.getUserID();
    },

    async updataCourseByHand() {
      if (!this.selectedPatientObj.brID) {
        this.$message({
          type: "error",
          message: "暂无患者数据",
        });
        return;
      }
      await this.updataCourse();
      this.$message({
        type: "success",
        message: "刷新完成!",
      });
    },

    async updataCourse() {
      const text = document.querySelector(".vc-title").innerText;
      let temp = text.split(" ");
      let tempTime = "";
      let year = "1001";
      let month = "01";
      if (temp && temp.length >= 2) {
        year = temp[1];
        let index = MonthInfo.findIndex((item) => {
          return item.label == temp[0];
        });
        if (index >= 0) {
          month = MonthInfo[index].id;
        }
      }
      tempTime = year + "-" + month;

      let param2 = {
        // "Id": this.selectedPatientObj.patientId,
        Id: "br" + this.selectedPatientObj.brID + "cgm",
        Time: tempTime,
      };
      // console.log("获取时间：",param2);

      // 2022.11.28王昕卓修改接口
      Promise.all([
        await request({
          url: "http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/syscontrolassess/getbrcgmsjbmy",
          method: "post",
          data: {
            brID: this.selectedPatientObj.brID,
            time: tempTime,
          },
        }),
      ]).then(async (result) => {
        // Promise.all([
        // 	await GetPatientUserCGMTimeData(param2),

        // ]).then(async (result) => {

        // 2022.11.28王昕卓修改接口
        // if (result[0].data) {
        // 	this.disabledDates = result[0].data\
        // console.log(result[0].data.res)
        if (result[0]) {
          this.disabledDates = result[0].data.res;
        }
      });
    },
    // 设置默认参数
    setStartday2() {
      const date = new Date(this.date3);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const formattedDate = `${year}-${month}-${day}`;
      this.startday2 = formattedDate + "T00:00:00";
    },
    async optionChange(value) {
      this.SelectedValue = value;
      this.userid = value;

      this.initUserBaseView()
      this.showTime();
      this.getUserMessage();
      // console.log(this.getUserMessage(),'userMessage')
      this.getTwoWeek();
      this.getCurrentTime();
      this.dockerObj = this.$store.state.user.userObj;
      // console.log(data,'userInfo')
      this.globalLoading = this.$loading({
        lock: true,
        text: "数据加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.1)",
      });
      let param = {
        DoctorId: this.dockerObj.userId,
        UserId: null,
        Name: "",
        Phone: "",
      };
      this.loadingPatientUser = true;
      // console.log(this.dockerObj.userId);

      // 2022.11.18王昕卓修改接口形式,更换新接口
      this.globalLoading.close();
      this.loadingPatientUser = false;
      // 2022.12.10 aftercreate mounted
      // this.phone = this.$store.state.phone;
      this.doctorID = this.$store.state.doctorID;
      await axios
          .get("http://js.aps.ink:8090/getUserInfo", {
            // 传递的参数
            params: {
              id: value,
            },
            // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
          })
          .then((res) => {
            // 如果状态为400,说明登录失败
            if (res.data.status === 400) {
              // 登录失败提示
            } else {
              // console.log("第二次发送");
              // console.log(res.data.info);
              // console.log(res.data.startdaysList);

              this.patientdata = res.data.info;
              this.startdays = res.data.startdaysList;
              if (this.startdays.length) {
                this.startday2 = this.startdays[0] + "T00:00:00";
                this.showDate3 = false
                this.date3 = this.startdays[0];
                this.$nextTick(() => {
                  this.showDate3 = true
                })
              } else {
                this.setStartday2();
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      let index = this.patientList.findIndex((item) => {
        return item.brID == value;
      });

      if (index >= 0) {
        this.selectedPatientObj = JSON.parse(
            JSON.stringify(this.patientList[index])
        );
        this.brID = this.selectedPatientObj.brID;
        // if (this.selectedPatientObj.birthday) {
        // 	this.selectedPatientObj.ages = getAge(this.selectedPatientObj.birthday)
        // }

        this.userNameParase = nameParas(this.selectedPatientObj.xm);

        // if (this.selectedPatientObj.courseOfDisease) {
        // 	this.selectedPatientObj.courseOfDisease = timeParas(this.selectedPatientObj.courseOfDisease,
        // 		'time');
        // }
        if (this.selectedPatientObj.hblx) {
          let index2 = diseaseTypeOption.findIndex((T) => {
            return T.id == this.selectedPatientObj.hblx;
          });
          if (index2 >= 0) {
            this.selectedPatientObj.hblx = diseaseTypeOption[index2].label;
          } else {
            this.selectedPatientObj.hblx = this.selectedPatientObj.hblx;
          }
        }

        // this.updataCourse();
        // 更新病程日历
      }

      // this.globalLoading.close();

      this.$forceUpdate();
    },

    dynamicChart(val) {
      var obj = this.theme;
      var echarts = require("echarts");
      // echarts.registerTheme('vintage' , obj);
      // var myChart3 = echarts.init(document.getElementById('checkchart'),'vintage');
      var myChart3 = echarts.init(document.getElementById("checkchart"));

      // 初始 option
      // 2022.12.2王昕卓修改加载文字
      // myChart3.showLoading();
      myChart3.showLoading({
        text: "数据正在分析，请耐心等待...",
        maskColor: "#ffffff",
        textColor: "#404a59",
      });
      // 绘制图表
      myChart3.clear();
      myChart3.setOption({
        title: {
          text: "血糖值(mmol/L)",
          textStyle: {
            color: "#35384D",
            fontSize: 20,
          },
          top: "24px",
          left: "4%",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#FFFFFF",
          textStyle: {
            color: "#35384D",
          },
          padding: [
            10, // 上
            10, // 右
            20, // 下
            10, // 左
          ],
          extraCssText: "box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);",
        },
        legend: {
          bottom: "16px",
        },
        grid: {
          top: 70,
          left: "25px",
          right: "25px",
          bottom: "60px",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
          top: "38px",
          right: "41px",
        },
        xAxis: {
          type: "category",
          // 是否让线条和坐标轴有缝隙
          boundaryGap: true,
          data: [],
          // 坐标轴刻度标签的相关设置
          axisLabel: {
            color: "#9E9EA8",
          },
          // 坐标轴轴线相关设置
          axisLine: {
            show: false,
          },
          // 坐标轴刻度相关设置
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          // name: 'Glucose Measurements(mmg/dL)',
          nameTextStyle: {
            verticalAlign: "bottom",
            align: "left",
          },
          // min: '0',
          // max: '25',
          nameRotate: 0,
          // 坐标轴刻度标签的相关设置
          axisLabel: {
            color: "#9E9EA8",
          },
          // 坐标轴轴线相关设置
          axisLine: {
            show: false,
          },
          // 坐标轴刻度相关设置
          axisTick: {
            show: false,
          },
          // 坐标轴在 grid 区域中的分隔线
          splitLine: {
            show: true,
            lineStyle: {
              color: "#F7F9FB",
            },
          },
        },
        series: [],
      });
      // 让图表跟随屏幕自动的去适应
      window.addEventListener("resize", function () {
        myChart3.resize();
      });
      let sdtime1 = new Date(this.endtime);
      sdtime1 = new Date(sdtime1);
      let startTime = new Date(sdtime1).setHours(
          new Date(sdtime1).getHours() - this.val
      ); //小时
      startTime = new Date(startTime);
      // startTime = this.dateFormat(startTime)
      this.begintime = this.dateFormat(startTime);

      this.requestData(myChart3, val);
    },

    async requestData(obj, val) {
      // 2022.11.24王昕卓修改接口
      // const url = '/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart'

      // let sdtime1 = new Date().setHours(new Date().getHours() + 8)
      let sdtime1 = new Date(this.endtime);
      sdtime1 = new Date(sdtime1);
      // console.log(sdtime1 + '-' + this.endtime)
      let sdtime2 = new Date(sdtime1).setHours(
          new Date(sdtime1).getHours() - 12
      ); //小时
      sdtime2 = new Date(sdtime2);
      let sdtime3 = new Date(sdtime1).setDate(new Date(sdtime1).getDate() - 1); //1天
      sdtime3 = new Date(sdtime3);
      // sdtime3 = sdtime3.setHours(sdtime3.getHours() + 8)
      // sdtime3 = new Date(sdtime3)
      let sdtime4 = new Date(sdtime1).setDate(new Date(sdtime1).getDate() - 2); //1周
      sdtime4 = new Date(sdtime4);
      // sdtime4 = sdtime4.setHours(sdtime4.getHours() + 8)
      // sdtime4 = new Date(sdtime4)
      let sdtimen = new Date();
      let startTime = new Date();
      if (val === 12) {
        startTime = new Date(sdtime2);
        // console.log(this.endtime + "-" + startTime);
      } else if (val === 24) {
        startTime = new Date(sdtime3);
        // console.log(sdtime1 + "-" + startTime);
      } else if (val === 48) {
        startTime = new Date(sdtime4);
        // console.log(sdtime1 + "-" + startTime);
      }

      let ttt1 = new Date(this.begintime);
      let ttt2 = new Date(this.endtime);

      if (ttt1.getTime() == NaN || ttt2.getTime() == NaN) {
        this.$message({
          type: "error",
          message: "日期错误!",
        });

        return;
      }
      let postdata2 = {
        // "bl_ID": this.selectedPatientObj.patientId,
        // "begintime": ttt1.getTime(), //2021-5-8 进行修改接口的post数据，将标准时间改为时间戳
        // "br_ID": 'br' + this.selectedPatientObj.brID + 'cgm',
        // "endtime": ttt2.getTime()
        begintime: 1167810699000,
        br_ID: "br20005cgm",
        endtime: 1667810699000,
      };

      // 2022.11.22王昕卓更改接口

      // 	await request({
      // 		url: '/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart',
      // 		method: 'post',
      // 		data: 'postdata2'
      // 	})
      // ]).then(result => {
      // const {CGMycjc, CGMcjctj, CGMjlsj, CGMjl} = await request({
      // 	url: '/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart',
      // 	method: 'post',
      // 	data: 'postdata2'
      // });
      // console.log(CGMycjc, CGMcjctj, CGMjlsj, CGMjl)

      // request({
      // 	url: '/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart',
      // 	method: 'post'
      // },res => {
      // 	console.log(res)
      // 	let result = res

      // Promise.all([
      // 	await request({
      // 		url: '/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart',
      // 		method : 'post',
      // 		ContentType : 'application/json',
      // 		data : postdata2
      // 	})
      // ]).then(result => {

      // Promise.all([
      //   await request({
      //     url: "http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart",
      //     method: "post",
      //     data: {
      //       begintime: ttt1.getTime(),
      //       br_ID: "br" + this.selectedPatientObj.brID + "cgm",
      //       endtime: ttt2.getTime(),
      //     },
      //   }),
      // ])
      //   .then((result) => {
      //     // console.log(result)
      //
      //     // Promise.all([
      //     // 	await GetCGMyichangjianceForlinechart(postdata2),
      //     // ]).then(result => {
      //
      //     obj.hideLoading();
      //     // console.log(result)
      //
      //     if (result[0]) {
      //       if (result[0].CGMjl.length === 0) {
      //         this.$message({
      //           type: "error",
      //           message: "您所选择的时间段内没有数据!",
      //         });
      //       } else if (result[0].CGMjl.length < 12 * val) {
      //         // alert("数据不足" + val + "小时")
      //         this.$message({
      //           type: "error",
      //           message: "数据不足" + val + "小时",
      //         });
      //       }
      //       let CGMycjc = [];
      //       let CGMjlsj = [];
      //       let CGMjl = [];
      //       let CGMycjctj = [];
      //       let CGMxzz = [];
      //       let abnormal1 = []; // 异常1虚线Y轴坐标初始化容器
      //       let abnormal2 = [];
      //       let abnormal3 = [];
      //       let abnormal4 = [];
      //       let abnormal5 = [];
      //       let abnormal6 = [];
      //       let abnormal7 = [];
      //       let abnormal8 = [];
      //       let index1 = []; // 异常1索引存储器
      //       let index2 = [];
      //       let index3 = [];
      //       let index4 = [];
      //       let index5 = [];
      //       let index6 = [];
      //       let differ = [];
      //       let num1 = 0; // 异常1计数器
      //       let num2 = 0; // 异常2计数器
      //       let num3 = 0; // 异常3计数器
      //       let num4 = 0; // 异常4计数器
      //       let num5 = 0; // 异常5计数器
      //       let num6 = 0; // 异常6计数器
      //       let total = 0;
      //       CGMjl = result[0].CGMjl;
      //       CGMxzz = result[0].CGMxzz;
      //       CGMycjctj = result[0].CGMycjctj;
      //       total = result[0].CGMjl.length;
      //       let value1 = "";
      //       let value2 = "";
      //       let value3 = "";
      //       let value4 = "";
      //       let value5 = "";
      //       let value6 = "";
      //       let value0 = "";
      //       if (CGMycjctj) {
      //         value1 = CGMycjctj["1.0"] ? CGMycjctj["1.0"] : 0; // PISA故障
      //         value2 = CGMycjctj["2.0"] ? CGMycjctj["2.0"] : 0; // 尖峰故障
      //         value3 = CGMycjctj["3.0"] ? CGMycjctj["3.0"] : 0; // 漂移故障
      //         value4 = CGMycjctj["4.0"] ? CGMycjctj["4.0"] : 0; // 阶跃故障
      //         value5 = CGMycjctj["5.0"] ? CGMycjctj["5.0"] : 0; // 缺失故障
      //         value6 = CGMycjctj["0.5"] ? CGMycjctj["0.5"] : 0; // 未知故障
      //         value0 = CGMycjctj["0.0"] ? CGMycjctj["0.0"] : 0; // 正常数据点
      //       } else {
      //         value1 = 0;
      //         value2 = 0;
      //         value3 = 0;
      //         value4 = 0;
      //         value5 = 0;
      //         value6 = 0;
      //         value0 = 0;
      //       }
      //
      //       for (var i = 0; i < result[0].CGMjlsj.length; i++) {
      //         if (CGMjl[i] == CGMxzz[i]) {
      //           differ[i] = 0;
      //         } else {
      //           differ[i] = CGMxzz[i];
      //         }
      //       }
      //       for (var i = 0; i < result[0].CGMjlsj.length; i++) {
      //         CGMjlsj[i] = this.timestap_alert(result[0].CGMjlsj[i]);
      //         abnormal1[i] = -2;
      //         abnormal2[i] = -4;
      //         abnormal3[i] = -6;
      //         abnormal4[i] = -8;
      //         abnormal5[i] = 70;
      //         abnormal6[i] = 54;
      //         abnormal7[i] = 180;
      //         abnormal8[i] = 250;
      //       }
      //       for (var j = 0; j < result[0].CGMycjc.length; j++) {
      //         switch (result[0].CGMycjc[j]) {
      //           case 1: // abnormal1[num1] = response.data.CGMycjc[j]              //用于存储PISA异常类型
      //             index1[num1] = j; //用于存储异常数据索引
      //             num1++; //循环结束后num1等于异常1个数;
      //             break;
      //           case 2: // abnormal2[num2] = response.data.CGMycjc[j]              //用于存储尖峰异常类型;
      //             index2[num2] = j; //用于存储异常数据索引
      //             num2++; //循环结束后num2等于异常2个数;
      //             break;
      //           case 3: // abnormal3[num3] = response.data.CGMycjc[j]              //用于存储漂移异常类型;
      //             index3[num3] = j; //用于存储异常数据索引
      //             num3++; //循环结束后num3等于异常3个数;
      //             break;
      //           case 4: // abnormal4[num4] = response.data.CGMycjc[j]              //用于存储阶跃异常类型;
      //             index4[num4] = j; //用于存储异常数据索引
      //             num4++; //循环结束后num4等于异常4个数;
      //             break;
      //           case 5: // abnormal5[num5] = response.data.CGMycjc[j]              //用于存储缺失异常类型;
      //             index5[num5] = j; //用于存储异常数据索引
      //             num5++; //循环结束后num5等于异常5个数;
      //             break;
      //           // 2022.12.7王昕卓添加噪声故障
      //           case 0.5: // abnormal6[num6] = response.data.CGMycjc[j]              //用于存储噪声异常类型;
      //             index6[num6] = j; //用于存储异常数据索引
      //             num6++; //循环结束后num6等于异常6个数;
      //             break;
      //           default:
      //             break;
      //         }
      //       }
      //       let abPoint1 = [];
      //       let abPoint2 = [];
      //       let abPoint3 = [];
      //       let abPoint4 = [];
      //       let abPoint5 = [];
      //       let abPoint6 = [];
      //       for (var n = 0; n < num1; n++) {
      //         abPoint1[n] = {
      //           name: "PISA",
      //           coord: [index1[n], -2],
      //           // "value":abnormal1[n],
      //           symbolSize: 10,
      //           symbol: "pin",
      //           fontsize: 20,
      //           label: {
      //             fontsize: 20,
      //           },
      //           itemStyle: {
      //             opacity: 0.7,
      //             color: {
      //               type: "linear",
      //               x: 0,
      //               y: 0,
      //               x2: 0,
      //               y2: 1,
      //               colorStops: [
      //                 {
      //                   offset: 0,
      //                   color: "#00ff00", // 0% 处的颜色
      //                 },
      //                 {
      //                   offset: 1,
      //                   color: "#00ff00", // 100% 处的颜色
      //                 },
      //               ],
      //               global: false, // 缺省为 false
      //             },
      //           },
      //         };
      //       }
      //       for (var n = 0; n < num2; n++) {
      //         abPoint2[n] = {
      //           name: "尖峰故障",
      //           coord: [index2[n], -4],
      //           // "value":abnormal1[n],
      //           symbolSize: 10,
      //           symbol: "circle",
      //           fontsize: 20,
      //           label: {
      //             fontsize: 20,
      //           },
      //           itemStyle: {
      //             opacity: 0.7,
      //             color: "#ff0000",
      //           },
      //         };
      //       }
      //       for (var n = 0; n < num3; n++) {
      //         abPoint3[n] = {
      //           name: "漂移故障",
      //           coord: [index3[n], -6],
      //           // "value":abnormal1[n],
      //           symbolSize: 10,
      //           symbol: "triangle",
      //           fontsize: 20,
      //           label: {
      //             fontsize: 20,
      //           },
      //           itemStyle: {
      //             opacity: 0.7,
      //             color: "#b3b300",
      //           },
      //         };
      //       }
      //       for (var n = 0; n < num4; n++) {
      //         abPoint4[n] = {
      //           name: "阶跃故障",
      //           coord: [index4[n], -8],
      //           // "value":abnormal1[n],
      //           symbolSize: 10,
      //           symbol: "diamond",
      //           fontsize: 20,
      //           label: {
      //             fontsize: 20,
      //           },
      //           itemStyle: {
      //             opacity: 0.7,
      //             color: "#ff7cf9",
      //           },
      //         };
      //       }
      //       for (var n = 0; n < num5; n++) {
      //         abPoint5[n] = {
      //           name: "缺失故障",
      //           coord: [index5[n], -10],
      //           // "value":abnormal1[n],
      //           symbolSize: 10,
      //           symbol: "arrow",
      //           fontsize: 20,
      //           label: {
      //             fontsize: 20,
      //           },
      //           itemStyle: {
      //             opacity: 0.7,
      //             // "color": {
      //             // 	"type": 'linear',
      //             // 	"x": 0,
      //             // 	"y": 0,
      //             // 	"x2": 0,
      //             // 	"y2": 1,
      //             // 	"colorStops": [{
      //             // 		"offset": 0,
      //             // 		"color": '#ff6600' // 0% 处的颜色
      //             // 	}, {
      //             // 		"offset": 1,
      //             // 		"color": '#ff6600' // 100% 处的颜色
      //             // 	}],
      //             // 	"global": false // 缺省为 false
      //             // }
      //             color: "#ff6600",
      //           },
      //         };
      //       }
      //       for (var n = 0; n < num6; n++) {
      //         abPoint6[n] = {
      //           name: "噪声故障",
      //           coord: [index6[n], -12],
      //           // "value":abnormal1[n],
      //           symbolSize: 10,
      //           symbol: "emptyCircle",
      //           fontsize: 20,
      //           label: {
      //             fontsize: 20,
      //           },
      //           itemStyle: {
      //             opacity: 0.7,
      //             color: {
      //               type: "linear",
      //               x: 0,
      //               y: 0,
      //               x2: 0,
      //               y2: 1,
      //               colorStops: [
      //                 {
      //                   offset: 0,
      //                   color: "#00ffff", // 0% 处的颜色
      //                 },
      //                 {
      //                   offset: 1,
      //                   color: "#00ffff", // 100% 处的颜色
      //                 },
      //               ],
      //               global: false, // 缺省为 false
      //             },
      //           },
      //         };
      //       }
      //       !this.chartSetOption(
      //         obj,
      //         CGMjlsj,
      //         CGMjl,
      //         CGMxzz,
      //         abPoint1,
      //         abnormal1,
      //         abPoint2,
      //         abnormal2,
      //         abPoint3,
      //         abnormal3,
      //         abPoint4,
      //         abnormal4,
      //         abPoint5,
      //         abnormal5,
      //         abPoint6,
      //         abnormal6,
      //         abnormal7,
      //         abnormal8,
      //         differ
      //       );
      //       // this.showchart(value1,value2,value3,value4,value5,value6)
      //
      //       var p0 = value0 / total; // 正确率
      //       var str0 = Number(p0 * 100).toFixed();
      //       // str0+="%";
      //       var p1 = value5 / total; // 缺失率
      //       var str1 = Number(p1 * 100).toFixed();
      //       // str1+="%";
      //       // var p2 = (total - value0 - value1 - value6)/total                       // 除了 PISA 故障和未知故障
      //       // var str2 = Number(p2*100).toFixed()
      //       // str2+="%";
      //       var p3 = (value3 + value6) / total;
      //       // console.log(str0 + '-' + str1 + '-' + str2 + '-')
      //       this.dataQuality = [];
      //
      //       if (total === 0) {
      //         this.dataQuality[0] = 0;
      //         this.dataQuality[1] = "--";
      //         this.dataQuality[2] = "--";
      //       } else if (p0 >= 0.9) {
      //         this.dataQuality[0] = str0;
      //         this.dataQuality[1] = str1;
      //         this.dataQuality[2] = "较高";
      //       } else if (p0 < 0.9 && p0 > 0.7) {
      //         this.dataQuality[0] = str0;
      //         this.dataQuality[1] = str1;
      //         this.dataQuality[2] = "中等";
      //       } else if (p0 <= 0.7 && p0 > 0) {
      //         this.dataQuality[0] = str0;
      //         this.dataQuality[1] = str1;
      //         this.dataQuality[2] = "较差";
      //       } else {
      //       }
      //       this.analysisData(value1, value5, p0, total, value2 + value4, p3);
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // 2023.2.20王昕卓添加数据分析接口
      Promise.all([
        await request({
          url: "http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/algorithm/GetFGMpingu",
          method: "post",
          data: {
            begintime: ttt1.getTime(),
            br_ID: "br" + this.selectedPatientObj.brID + "cgm",
            endtime: ttt2.getTime(),
          },
        }),
      ])
          .then((result) => {
            // console.log(result)

            this.MSG = result[0].a6.MSG.toFixed(2);
            this.SD = result[0].a6.SD.toFixed(2);
            this.GMI = result[0].a6.GMI.toFixed(2);
            this.CV = result[0].a6.CV.toFixed(2);
            this.TAR = result[0].a6.TAR.toFixed(2);
            this.TBR = result[0].a6.TBR.toFixed(2);
            this.HypoCount = result[0].a6.HypoCount.toFixed(0);
            this.TIR = result[0].a6.TIR.toFixed(2);
          })
          .catch((error) => {
            console.log(error);
          });
      //2023/4/18杨振宇添加夜间分析和日间分析

      Promise.all([
        await request({
          url: "http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/algorithm/GetFGMdaypingu",
          method: "post",
          data: {
            begintime: ttt1.getTime(),
            br_ID: "br" + this.selectedPatientObj.brID + "cgm",
            endtime: ttt2.getTime(),
          },
        }),
      ])
          .then((result) => {
            // console.log(result)

            this.dayMSG = result[0].a6.MSG.toFixed(2);
            this.daySD = result[0].a6.SD.toFixed(2);
            this.dayGMI = result[0].a6.GMI.toFixed(2);
            this.dayCV = result[0].a6.CV.toFixed(2);
            this.dayTAR = result[0].a6.TAR.toFixed(2);
            this.dayTBR = result[0].a6.TBR.toFixed(2);
            this.dayHypoCount = result[0].a6.HypoCount.toFixed(0);
            this.dayTIR = result[0].a6.TIR.toFixed(2);
          })
          .catch((error) => {
            console.log(error);
          });
      Promise.all([
        await request({
          url: "http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/algorithm/GetFGMnightpingu",
          method: "post",
          data: {
            begintime: ttt1.getTime(),
            br_ID: "br" + this.selectedPatientObj.brID + "cgm",
            endtime: ttt2.getTime(),
          },
        }),
      ])
          .then((result) => {
            // console.log(result)

            this.nightMSG = result[0].a6.MSG.toFixed(2);
            this.nightSD = result[0].a6.SD.toFixed(2);
            this.nightGMI = result[0].a6.GMI.toFixed(2);
            this.nightCV = result[0].a6.CV.toFixed(2);
            this.nightTAR = result[0].a6.TAR.toFixed(2);
            this.nightTBR = result[0].a6.TBR.toFixed(2);
            this.nightHypoCount = result[0].a6.HypoCount.toFixed(0);
            this.nightTIR = result[0].a6.TIR.toFixed(2);

            // console.log(this.TWOPer95);
          })
          .catch((error) => {
            console.log(error);
          });
    },

    chartSetOption(
        obj,
        xAxisdata,
        yAxisdata2,
        CGMxzz,
        abPoint1,
        abnormal1,
        abPoint2,
        abnormal2,
        abPoint3,
        abnormal3,
        abPoint4,
        abnormal4,
        abPoint5,
        abnormal5,
        abPoint6,
        abnormal6,
        abnormal7,
        abnormal8,
        differ
    ) {
      obj.hideLoading();
      obj.setOption({
        title: {
          text: "血糖值(mmol/L)",
          textStyle: {
            color: "#35384D",
            fontSize: 20,
          },
          top: "24px",
          left: "4%",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#FFFFFF",
          textStyle: {
            color: "#35384D",
          },
          padding: [
            10, // 上
            10, // 右
            20, // 下
            10, // 左
          ],
          extraCssText: "box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);",
        },
        legend: {
          // 2022.12.7王昕卓添加噪声故障
          data: [
            "数据修正值",
            "数据记录",
            "PISA",
            "尖峰故障",
            "漂移故障",
            "阶跃故障",
            "缺失故障",
            "噪声故障",
            "低血糖阈值",
            "高血糖阈值",
            "差异性",
          ],
          bottom: "16px",
        },
        grid: {
          left: "25px",
          right: "25px",
          bottom: "60px",
          show: false,
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
          top: "38px",
          right: "41px",
        },
        xAxis: {
          type: "category",
          // 是否让线条和坐标轴有缝隙
          boundaryGap: true,
          // 坐标轴刻度标签的相关设置
          axisLabel: {
            color: "#9E9EA8",
          },
          // 坐标轴轴线相关设置
          axisLine: {
            show: false,
          },
          // 坐标轴刻度相关设置
          axisTick: {
            show: false,
          },
          data: xAxisdata,
          // type : 'time',
          axisLabel: {
            //  如果这个字段不设置，echarts会根据屏宽及横坐标数据自动给出间隔
            interval: 23, // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
            // rotate: 40 // 横坐标上label的倾斜度
          },
        },
        yAxis: [
          {
            type: "value",
            // name: '血糖测量值(mmol/L)',
            nameTextStyle: {
              verticalAlign: "bottom",

              align: "left",
            },
            min: "0",
            // max: '25',
            // 坐标轴刻度标签的相关设置
            axisLabel: {
              color: "#9E9EA8",
            },
            // 坐标轴轴线相关设置
            axisLine: {
              show: false,
            },
            // 坐标轴刻度相关设置
            axisTick: {
              show: false,
            },
            // 坐标轴在 grid 区域中的分隔线
            splitLine: {
              show: true,
              lineStyle: {
                color: "#F7F9FB",
              },
            },
            // splitArea: {
            // 	show: true
            // },
            nameRotate: 0,
          },
          {
            type: "value",
            position: "right",
            axisLabel: {
              fomatter: "{value}%",
            },
            min: 0,
            max: 100,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              alignWithLabel: true,
              interval: 20, // 设置刻度间隔为20
            },
            axisLabel: {
              formatter: function (value) {
                return value + "%"; // 显示百分比
              },
            },
          },
        ],
        series: [
          {
            name: "5%",
            type: "line",
            smooth: "true",

            symbol: "none",
            color: "#FFFFFF",
            // color: '#da9a39',
            areaStyle: {
              normal: {
                color: "transparent", // 设置填充颜色为透明
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [
              91.0, 90.0, 89.0, 87.0, 86.0, 85.0, 83.0, 76.0, 72.0, 101.0, 71.0,
              69.0, 68.0, 70.0, 73.0, 73.0, 72.0, 71.0, 71.0, 71.0, 71.0, 70.0,
              69.0, 68.0, 68.0, 69.0, 69.0, 68.0, 68.0, 66.0, 65.0, 62.0, 57.0,
              55.0, 50.0, 46.0, 43.0, 40.0, 40.0, 40.0, 42.0, 45.0, 43.0, 43.0,
              44.0, 47.0, 45.0, 51.0, 53.0, 51.0, 51.0, 51.0, 52.0, 59.0, 58.0,
              57.0, 59.0, 59.0, 60.0, 61.0, 63.0, 67.0, 76.0, 84.0, 85.0, 82.0,
              80.0, 80.0, 77.0, 74.0, 74.0, 75.0, 74.0, 72.0, 70.0, 68.0, 68.0,
              67.0, 67.0, 67.0, 68.0, 68.0, 69.0, 69.0, 68.0, 68.0, 68.0, 68.0,
              67.0, 67.0, 69.0, 71.0, 71.0, 69.0, 70.0, 70.0, 70.0, 70.0, 70.0,
              69.0, 70.0, 70.0, 68.0, 69.0, 74.0, 80.0, 79.0, 77.0, 73.0, 71.0,
              70.0, 70.0, 69.0, 68.0, 66.0, 63.0, 62.0, 61.0, 60.0, 61.0, 61.0,
              65.0, 69.0, 71.0, 71.0, 68.0, 67.0, 66.0, 63.0, 61.0, 60.0, 59.0,
              59.0, 57.0, 58.0, 60.0, 53.0, 55.0, 61.0, 73.0, 78.0, 81.0, 79.0,
              76.0, 72.0, 67.0, 64.0, 64.0, 65.0, 63.0, 62.0, 61.0, 57.0, 56.0,
              58.0, 57.0, 57.0, 58.0, 68.0, 70.0, 73.0, 78.0, 87.0, 86.0, 79.0,
              77.0, 78.0, 78.0, 76.0, 76.0, 79.0, 82.0, 80.0, 78.0, 75.0, 70.0,
              67.0, 64.0, 63.0, 64.0, 67.0, 70.0, 75.0, 80.0, 86.0, 91.0, 95.0,
              95.0, 92.0, 88.0, 84.0, 80.0, 79.0, 79.0, 77.0, 76.0, 74.0, 72.0,
              69.0, 68.0, 67.0, 65.0, 64.0, 62.0, 65.0, 76.0, 76.0, 76.0,

              77.0, 74.0, 73.0, 73.0, 74.0, 74.0, 76.0, 79.0, 81.0, 82.0, 77.0,
              75.0, 74.0, 74.0, 73.0, 72.0, 66.0, 52.0, 50.0, 49.0, 51.0, 53.0,
              59.0, 65.0, 69.0, 76.0, 75.0, 74.0, 72.0, 72.0, 76.0, 82.0, 76.0,
              72.0, 69.0, 68.0, 68.0, 65.0, 65.0, 63.0, 52.0, 50.0, 51.0, 53.0,
              58.0, 62.0, 64.0, 64.0, 65.0, 69.0, 69.0, 70.0, 70.0, 70.0, 71.0,
              72.0, 73.0, 75.0, 79.0, 79.0, 79.0, 84.0, 86.0, 76.0, 84.0, 82.0,
              83.0, 86.0, 90.0, 96.0, 99.0, 102.0, 107.0, 105.0, 108.0, 104.0,
              100.0, 96.0, 94.0, 92.0,
            ],
            lineStyle: {
              width: 1,
              // type: 'dashed'
            },
          },
          {
            name: "25%",
            type: "line",
            smooth: "true",
            symbol: "none",
            color: "#98FB98",
            // color: '#da9a39',
            areaStyle: {
              normal: {
                color: "transparent", // 设置填充颜色为透明
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [
              128.0, 128.0, 127.0, 124.0, 118.0, 120.0, 110.0, 117.0, 115.0,
              119.0, 114.0, 110.0, 105.0, 103.0, 103.0, 101.0, 100.0, 100.0,
              100.0, 100.0, 100.0, 100.0, 99.0, 97.0, 98.0, 100.0, 106.0, 99.0,
              89.0, 77.0, 77.0, 78.0, 78.0, 79.0, 75.0, 70.0, 70.0, 103.0,
              102.0, 101.0, 100.0, 98.0, 96.0, 91.0, 91.0, 93.0, 92.0, 90.0,
              89.0, 88.0, 88.0, 86.0, 100.0, 103.0, 103.0, 103.0, 103.0, 102.0,
              101.0, 101.0, 101.0, 98.0, 98.0, 94.0, 101.0, 101.0, 101.0, 95.0,
              92.0, 93.0, 92.0, 89.0, 90.0, 89.0, 89.0, 87.0, 87.0, 86.0, 85.0,
              83.0, 82.0, 81.0, 80.0, 80.0, 82.0, 78.0, 77.0, 77.0, 78.0, 77.0,
              75.0, 76.0, 81.0, 86.0, 86.0, 82.0, 81.0, 86.0, 82.0, 79.0, 79.0,
              79.0, 82.0, 80.0, 81.0, 86.0, 94.0, 105.0, 114.0, 114.0, 121.0,
              120.0, 112.0, 108.0, 106.0, 103.0, 109.0, 114.0, 118.0, 118.0,
              120.0, 121.0, 121.0, 121.0, 120.0, 119.0, 118.0, 116.0, 115.0,
              113.0, 111.0, 105.0, 96.0, 86.0, 81.0, 87.0, 91.0, 89.0, 92.0,
              95.0, 96.0, 95.0, 94.0, 93.0, 91.0, 89.0, 89.0, 86.0, 79.0, 75.0,
              71.0, 71.0, 73.0, 76.0, 76.0, 88.0, 94.0, 98.0, 104.0, 108.0,
              109.0, 110.0, 108.0, 106.0, 109.0, 110.0, 109.0, 109.0, 106.0,
              103.0, 101.0, 99.0, 95.0, 92.0, 89.0, 87.0, 85.0, 83.0, 84.0,
              90.0, 99.0, 104.0, 104.0, 108.0, 111.0, 113.0, 110.0, 106.0,
              103.0, 101.0, 99.0, 101.0, 101.0, 103.0, 105.0, 106.0, 104.0,
              102.0, 88.0, 88.0, 89.0, 90.0, 98.0, 94.0, 95.0, 96.0, 90.0, 95.0,
              93.0, 91.0, 94.0, 96.0, 98.0, 100.0, 106.0, 104.0, 107.0, 109.0,
              110.0, 110.0, 106.0, 97.0, 104.0, 114.0, 122.0, 119.0, 118.0,
              117.0, 117.0, 118.0, 116.0, 117.0, 120.0, 119.0, 114.0, 115.0,
              101.0, 95.0, 105.0, 105.0, 93.0, 97.0, 89.0, 83.0, 82.0, 80.0,
              78.0, 77.0, 75.0, 76.0, 77.0, 78.0, 77.0, 78.0, 81.0, 78.0, 77.0,
              77.0, 77.0, 76.0, 80.0, 87.0, 93.0, 96.0, 99.0, 101.0, 101.0,
              102.0, 101.0, 94.0, 97.0, 100.0, 104.0, 108.0, 113.0, 118.0,
              119.0, 122.0, 128.0, 137.0, 140.0, 142.0, 120.0, 125.0, 133.0,
              129.0, 127.0, 126.0,
            ],

            lineStyle: {
              width: 2,
              // type: 'dashed'
            },
          },

          // 区域填充样式。设置后显示成区域面积图
          /*							areaStyle: {
                                                    // color: 'linear-gradient(#ececf5 #FFFFFF)'
                                                    color: '#ececf5'
                                                }*/

          // 2022.12.28王昕卓添加低血糖阈值

          //2022/4/20杨振宇添加差异性
          {
            name: "75%",
            type: "line",
            smooth: "true",
            symbol: "none",
            color: "#DAA520",
            emphasis: {
              focus: "series",
            },

            data: [
              165.0, 168.0, 172.0, 176.0, 178.0, 181.0, 155.0, 155.0, 156.0,
              157.0, 157.0, 156.0, 156.0, 158.0, 157.0, 156.0, 159.0, 163.0,
              165.0, 164.0, 166.0, 167.0, 167.0, 169.0, 169.0, 156.0, 154.0,
              153.0, 153.0, 154.0, 155.0, 157.0, 159.0, 160.0, 161.0, 164.0,
              166.0, 180.0, 182.0, 183.0, 184.0, 186.0, 189.0, 192.0, 191.0,
              184.0, 180.0, 176.0, 176.0, 176.0, 177.0, 176.0, 178.0, 179.0,
              180.0, 180.0, 182.0, 182.0, 182.0, 183.0, 183.0, 182.0, 181.0,
              179.0, 190.0, 204.0, 208.0, 206.0, 205.0, 198.0, 191.0, 184.0,
              178.0, 172.0, 173.0, 178.0, 184.0, 184.0, 165.0, 148.0, 156.0,
              167.0, 162.0, 155.0, 140.0, 137.0, 132.0, 129.0, 124.0, 119.0,
              113.0, 110.0, 106.0, 103.0, 101.0, 99.0, 101.0, 103.0, 105.0,
              117.0, 128.0, 137.0, 148.0, 161.0, 169.0, 167.0, 161.0, 163.0,
              183.0, 187.0, 191.0, 188.0, 186.0, 184.0, 184.0, 182.0, 179.0,
              177.0, 180.0, 191.0, 206.0, 223.0, 235.0, 231.0, 235.0, 246.0,
              249.0, 245.0, 237.0, 226.0, 217.0, 207.0, 199.0, 190.0, 181.0,
              173.0, 165.0, 159.0, 155.0, 163.0, 173.0, 166.0, 153.0, 140.0,
              149.0, 161.0, 162.0, 154.0, 144.0, 136.0, 132.0, 130.0, 129.0,
              129.0, 128.0, 126.0, 135.0, 153.0, 158.0, 160.0, 172.0, 168.0,
              160.0, 145.0, 141.0, 137.0, 143.0, 143.0, 150.0, 143.0, 141.0,
              145.0, 148.0, 148.0, 145.0, 147.0, 151.0, 151.0, 149.0, 152.0,
              155.0, 160.0, 161.0, 161.0, 161.0, 159.0, 168.0, 171.0, 177.0,
              183.0, 188.0, 193.0, 205.0, 211.0, 218.0, 223.0, 177.0, 229.0,
              229.0, 226.0, 180.0, 221.0, 216.0, 213.0, 209.0, 205.0, 200.0,
              197.0, 191.0, 190.0, 192.0, 192.0, 192.0, 191.0, 189.0, 187.0,
              180.0, 183.0, 181.0, 178.0, 176.0, 172.0, 167.0, 163.0, 160.0,
              157.0, 198.0, 199.0, 200.0, 198.0, 178.0, 181.0, 159.0, 158.0,
              149.0, 158.0, 152.0, 152.0, 150.0, 144.0, 144.0, 146.0, 153.0,
              158.0, 166.0, 166.0, 169.0, 168.0, 168.0, 174.0, 135.0, 141.0,
              147.0, 153.0, 159.0, 165.0, 102.0, 106.0, 110.0, 122.0, 137.0,
              152.0, 165.0, 173.0, 177.0, 178.0, 177.0, 176.0, 174.0, 169.0,
              165.0, 164.0, 171.0, 178.0, 186.0, 191.0, 186.0, 181.0, 176.0,
              171.0, 169.0, 166.0, 163.0, 160.0, 157.0, 158.0, 160.0, 162.0,
            ],
            tooltip: {
              show: false,
            },
            lineStyle: {
              width: 1,
            },
            areaStyle: {
              normal: {
                color: "transparent", // 设置填充颜色为透明
              },
            },
            emphasis: {
              focus: "series",
            },
            emphasis: {
              focus: "series",
            },
          },
          {
            name: "95%",
            type: "line",
            smooth: "true",
            symbol: "none",
            color: "#FFDEAD",
            emphasis: {
              focus: "series",
            },
            data: [
              165.0, 168.0, 172.0, 176.0, 178.0, 181.0, 155.0, 155.0, 156.0,
              157.0, 157.0, 156.0, 156.0, 158.0, 157.0, 156.0, 159.0, 163.0,
              165.0, 164.0, 166.0, 167.0, 167.0, 169.0, 169.0, 156.0, 154.0,
              153.0, 153.0, 154.0, 155.0, 157.0, 159.0, 160.0, 161.0, 164.0,
              166.0, 180.0, 182.0, 183.0, 184.0, 186.0, 189.0, 192.0, 191.0,
              184.0, 180.0, 176.0, 176.0, 176.0, 177.0, 176.0, 178.0, 179.0,
              180.0, 180.0, 182.0, 182.0, 182.0, 183.0, 183.0, 182.0, 181.0,
              179.0, 190.0, 204.0, 208.0, 206.0, 205.0, 198.0, 191.0, 184.0,
              178.0, 172.0, 173.0, 178.0, 184.0, 184.0, 165.0, 148.0, 156.0,
              167.0, 162.0, 155.0, 140.0, 137.0, 132.0, 129.0, 124.0, 119.0,
              113.0, 110.0, 106.0, 103.0, 101.0, 99.0, 101.0, 103.0, 105.0,
              117.0, 128.0, 137.0, 148.0, 161.0, 169.0, 167.0, 161.0, 163.0,
              183.0, 187.0, 191.0, 188.0, 186.0, 184.0, 184.0, 182.0, 179.0,
              177.0, 180.0, 191.0, 206.0, 223.0, 235.0, 231.0, 235.0, 246.0,
              249.0, 245.0, 237.0, 226.0, 217.0, 207.0, 199.0, 190.0, 181.0,
              173.0, 165.0, 159.0, 155.0, 163.0, 173.0, 166.0, 153.0, 140.0,
              149.0, 161.0, 162.0, 154.0, 144.0, 136.0, 132.0, 130.0, 129.0,
              129.0, 128.0, 126.0, 135.0, 153.0, 158.0, 160.0, 172.0, 168.0,
              160.0, 145.0, 141.0, 137.0, 143.0, 143.0, 150.0, 143.0, 141.0,
              145.0, 148.0, 148.0, 145.0, 147.0, 151.0, 151.0, 149.0, 152.0,
              155.0, 160.0, 161.0, 161.0, 161.0, 159.0, 168.0, 171.0, 177.0,
              183.0, 188.0, 193.0, 205.0, 211.0, 218.0, 223.0, 177.0, 229.0,
              229.0, 226.0, 180.0, 221.0, 216.0, 213.0, 209.0, 205.0, 200.0,
              197.0, 191.0, 190.0, 192.0, 192.0, 192.0, 191.0, 189.0, 187.0,
              180.0, 183.0, 181.0, 178.0, 176.0, 172.0, 167.0, 163.0, 160.0,
              157.0, 198.0, 199.0, 200.0, 198.0, 178.0, 181.0, 159.0, 158.0,
              149.0, 158.0, 152.0, 152.0, 150.0, 144.0, 144.0, 146.0, 153.0,
              158.0, 166.0, 166.0, 169.0, 168.0, 168.0, 174.0, 135.0, 141.0,
              147.0, 153.0, 159.0, 165.0, 102.0, 106.0, 110.0, 122.0, 137.0,
              152.0, 165.0, 173.0, 177.0, 178.0, 177.0, 176.0, 174.0, 169.0,
              165.0, 164.0, 171.0, 178.0, 186.0, 191.0, 186.0, 181.0, 176.0,
              171.0, 169.0, 166.0, 163.0, 160.0, 157.0, 158.0, 160.0, 162.0,
            ],
            areaStyle: {
              normal: {
                color: "transparent", // 设置填充颜色为透明
              },
            },

            emphasis: {
              focus: "series",
            },

            tooltip: {
              show: false,
            },
            lineStyle: {
              width: 2,
            },
          },
          {
            name: "50%",
            type: "line",
            color: "#228B22",
            smooth: "true",

            symbol: "none",
            areaStyle: {
              normal: {
                color: "#7CFC00", // 设置填充颜色为透明
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [
              146.0, 147.0, 147.0, 148.0, 145.0, 139.0, 135.0, 134.0, 133.0,
              140.0, 128.0, 127.0, 128.0, 129.0, 132.0, 138.0, 138.0, 136.0,
              138.0, 139.0, 142.0, 145.0, 144.0, 143.0, 141.0, 140.0, 140.0,
              139.0, 138.0, 137.0, 136.0, 135.0, 134.0, 133.0, 131.0, 130.0,
              130.0, 152.0, 147.0, 132.0, 127.0, 125.0, 124.0, 124.0, 123.0,
              122.0, 118.0, 115.0, 113.0, 111.0, 111.0, 116.0, 113.0, 111.0,
              116.0, 116.0, 116.0, 117.0, 118.0, 124.0, 120.0, 119.0, 116.0,
              114.0, 115.0, 132.0, 128.0, 135.0, 137.0, 137.0, 135.0, 133.0,
              130.0, 127.0, 121.0, 117.0, 114.0, 112.0, 111.0, 112.0, 118.0,
              119.0, 115.0, 115.0, 114.0, 114.0, 113.0, 111.0, 105.0, 108.0,
              106.0, 104.0, 95.0, 95.0, 96.0, 96.0, 94.0, 92.0, 93.0, 90.0,
              90.0, 90.0, 89.0, 98.0, 111.0, 116.0, 115.0, 119.0, 123.0, 125.0,
              124.0, 127.0, 129.0, 133.0, 132.0, 132.0, 134.0, 133.0, 130.0,
              136.0, 144.0, 158.0, 177.0, 187.0, 183.0, 174.0, 162.0, 148.0,
              142.0, 148.0, 148.0, 144.0, 145.0, 140.0, 136.0, 139.0, 137.0,
              129.0, 121.0, 112.0, 109.0, 109.0, 123.0, 118.0, 105.0, 101.0,
              98.0, 96.0, 93.0, 88.0, 83.0, 79.0, 79.0, 88.0, 98.0, 107.0,
              118.0, 124.0, 125.0, 124.0, 128.0, 130.0, 131.0, 133.0, 133.0,
              130.0, 134.0, 134.0, 136.0, 127.0, 116.0, 105.0, 99.0, 104.0,
              106.0, 104.0, 101.0, 98.0, 98.0, 102.0, 108.0, 115.0, 124.0,
              132.0, 141.0, 149.0, 148.0, 148.0, 148.0, 146.0, 145.0, 150.0,
              151.0, 148.0, 145.0, 143.0, 143.0, 143.0, 144.0, 149.0, 145.0,
              139.0, 134.0, 132.0, 132.0, 132.0, 118.0, 117.0, 118.0, 128.0,
              141.0, 145.0, 137.0, 121.0, 137.0, 158.0, 162.0, 150.0, 135.0,
              121.0, 123.0, 126.0, 130.0, 128.0, 124.0, 130.0, 146.0, 144.0,
              144.0, 142.0, 141.0, 149.0, 144.0, 141.0, 134.0, 136.0, 132.0,
              126.0, 119.0, 112.0, 107.0, 102.0, 103.0, 107.0, 104.0, 105.0,
              109.0, 110.0, 112.0, 118.0, 91.0, 87.0, 88.0, 92.0, 94.0, 93.0,
              91.0, 93.0, 95.0, 99.0, 101.0, 105.0, 110.0, 117.0, 129.0, 134.0,
              136.0, 138.0, 139.0, 140.0, 139.0, 137.0, 137.0, 138.0, 140.0,
              142.0, 144.0, 146.0, 148.0, 149.0, 150.0, 151.0, 146.0, 143.0,
              142.0, 144.0, 144.0, 145.0,
            ],
            // 线条样式
            // 2022.11.30王昕卓修改线条宽度
            // lineStyle: {
            // 	width: 5
            // },
            lineStyle: {
              width: 1,
            },
            // 区域填充样式。设置后显示成区域面积图
            /*							areaStyle: {
                                                        // color: 'linear-gradient(#ececf5 #FFFFFF)' max
                                                        color: '#ececf5'
                                                    }*/
          },

          {
            name: "1级TBR",
            type: "line",
            smooth: "true",
            symbol: "none",
            color: "#228B22",
            data: abnormal5,

            tooltip: {
              show: false,
            },
            lineStyle: {
              width: 2,
            },
          },
          {
            name: "正常",
            type: "line",
            smooth: "true",
            symbol: "none",
            color: "#228B22",
            data: abnormal7,
            // max
            tooltip: {
              show: false,
            },
            lineStyle: {
              width: 2,
            },
          },
          {
            name: "2级TAR",
            type: "line",
            smooth: "true",
            symbol: "none",
            color: "#FFFFFF",
            data: abnormal8,
            tooltip: {
              show: false,
            },
            lineStyle: {
              width: 2,
            },
          },
          {
            name: "2级TBR",
            type: "line",
            smooth: "true",
            symbol: "none",
            color: "#FFFFFF",

            data: abnormal6,
            tooltip: {
              show: false,
            },
            lineStyle: {
              width: 1,
            },
          },
        ],
      });
    },
    reqChange(val) {
      this.activeclass = [];
      this.val = "";
      this.val = val;
      for (var i = 0; i < 3; i++) {
        this.activeclass[i] = "changebtn";
      }
      switch (val) {
        case 12:
          this.activeclass[0] = "atclick";
          break;
        case 24:
          this.activeclass[1] = "atclick";
          break;
        case 48:
          this.activeclass[2] = "atclick";
          break;
      }

      // let sdtime1 = new Date().setHours(new Date().getHours() + 8)
      let sdtime1 = new Date(this.endtime);
      sdtime1 = new Date(sdtime1);
      // console.log(sdtime1 + '-' + this.endtime)
      let sdtime2 = new Date(sdtime1).setHours(
          new Date(sdtime1).getHours() - 12
      ); //小时
      sdtime2 = new Date(sdtime2);
      let sdtime3 = new Date(sdtime1).setDate(new Date(sdtime1).getDate() - 1); //1天
      sdtime3 = new Date(sdtime3);
      // sdtime3 = sdtime3.setHours(sdtime3.getHours() + 8)
      // sdtime3 = new Date(sdtime3)
      let sdtime4 = new Date(sdtime1).setDate(new Date(sdtime1).getDate() - 2); //1周
      sdtime4 = new Date(sdtime4);
      // sdtime4 = sdtime4.setHours(sdtime4.getHours() + 8)
      // sdtime4 = new Date(sdtime4)
      let sdtimen = new Date();
      let startTime = new Date();
      if (val === 12) {
        startTime = new Date(sdtime2);
        // console.log(this.endtime + '-' + startTime)
      } else if (val === 24) {
        startTime = new Date(sdtime3);
        // console.log(sdtime1 + '-' + startTime)
      } else if (val === 48) {
        startTime = new Date(sdtime4);
        // console.log(sdtime1 + '-' + startTime)
      }

      (this.begintime = startTime), (this.endtime = sdtime1.getTime());

      this.dynamicChart(val);
      // this.dataQualityChart();
      this.showTime();
    },
    showchart(value1, value2, value3, value4, value5, value6) {
      let bar_dev = this.$refs.chart1;
      if (bar_dev) {
        var obj = this.theme;
        var echarts = require("echarts");
        // echarts.registerTheme('vintage' , obj);
        let myChart1 = echarts.init(bar_dev);
        myChart1.setOption({
          title: {
            // text: '产量玫瑰图',
            fontSize: 15,
          },
          backgroundColor: "#BC80AD",
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          grid: {
            left: "25px",
            right: "25px",
            top: "20px",
          },
          legend: {
            right: 35,
            orient: "vertical",
            data: ["PISA", "尖峰", "漂移", "阶跃", "缺失", "噪声"],
          },
          toolbox: {
            show: true,
            feature: {
              // mark: {show: true},
              // dataView: {show: true, readOnly: false},           //数据视图选项、按钮图标
              magicType: {
                show: true,
                type: ["pie", "funnel"],
              },
              // restore: {show: true},                             //刷新按钮图标
              // saveAsImage: {show: true}
            },
          },
          series: [
            {
              name: "低血糖预警次数",
              type: "pie",
              // radius: [30, 50],
              color: [
                "#00ff00",
                "#ff0000",
                "#b3b300",
                "#ff7cf9",
                "#ff6600",
                "#00ffff",
                "#efa18d",
                "#787464",
                "#cc7e63",
                "#724e58",
                "#4b565b",
              ],
              center: ["36%", "51%"],
              radius: 65,
              avoidLabelOverlap: false,
              // label: {
              //  show: false,
              //  position: 'center'
              // },
              label: {
                show: false, //
              },
              emphasis: {
                label: {
                  // 鼠标移动到图上展示框
                  show: true,
                  fontSize: "12",
                  fontWeight: "600",
                },
              },
              // roseType: 'area',
              data: [
                {
                  value: value1,
                  name: "PISA",
                },
                {
                  value: value2,
                  name: "尖峰",
                },
                {
                  value: value3,
                  name: "漂移",
                },
                {
                  value: value4,
                  name: "阶跃",
                },
                {
                  value: value5,
                  name: "缺失",
                },
                {
                  value: value6,
                  name: "噪声",
                },
              ],
            },
          ],
        });
      }
    },
    requestpatientmsg() {
      this.bl_Id = this.getCookie("bl_Id");
      this.brName = this.getCookie("brName");
      this.brXb = this.getCookie("brXb");
      this.type = this.getCookie("type");
      this.csnyr = this.getCookie("csnyr");
      this.qzrq = this.getCookie("qzrq");
      this.ysID = this.getCookie("ysID");
      this.ysName = this.getCookie("ysName");
    },
    updatepatientmsg() {
      //已被弃用
      request(
          {
            url:
                "/xuetangjiance/xuetangjiance/sys-patient-user/cxbrxx/" + this.brID, //吴佳琪201-5-7修改新接口框架 methods
            method: "get",
          },
          (res) => {
            let requestData = res.data.data.res;
            this.bl_Id = requestData.brID;
            this.brName = requestData.name;
            this.brXb = requestData.sex;
            this.type = requestData.hblx;
            this.csnyr = this.timestap_ageCac(requestData.csnyr);
            this.qzrq = this.timestap_bcCac(requestData.qzrq);
            document.cookie = "bl_Id=" + this.bl_Id;
            document.cookie = "brName=" + this.brName;
            document.cookie = "brXb=" + this.brXb;
            document.cookie = "type=" + this.type;
            document.cookie = "csnyr=" + this.csnyr;
            document.cookie = "qzrq=" + this.qzrq;
          },
          (err) => {
            console.log(err);
          }
      );
    },
    analysisData(value1, value5, p0, total, valueplus, p3) {
      // console.log(value1,fault,value5,p0)
      this.suggestion = [];
      let length = 0;
      if (value1 > 15) {
        this.suggestion[length] = "发生PISA故障较多，请患者尽量避免压到传感器";
        length++;
      } else if (p3 > 0.05 || valueplus > 12) {
        this.suggestion[length] =
            "数据受外部干扰较大，请减少干扰并及时更换采集芯片";
        length++;
      } else if (value5 > 12) {
        this.suggestion[length] =
            "为了避免数据缺失与停滞，请患者不要取下设备，并及时更换采集芯片";
        length++;
      }
          // else if(p0 > 0.95){
          // 	this.suggestion[length] = '总体数据质量很好'
          // 	length++
      // }
      else {
        if (total === 0) {
          this.suggestion[length] = "当前时段内没有患者数据";
        } else if (p0 >= 0.9) {
          this.suggestion[length] = "总体数据质量较高,无临床使用建议";
        } else if (p0 > 0.7 && p0 < 0.9) {
          this.suggestion[length] = "总体数据质量中等,无临床使用建议";
        } else {
          this.suggestion[length] = "总体数据质量较差,无临床使用建议";
        }
      }
    },
    getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
      }
      return "";
    },

    async dataInitialization() {
      let that = this;

      let param = {
        // "bl_ID": this.selectedPatientObj.patientId,
        bl_ID: "br" + this.selectedPatientObj.brID + "cgm",
      };
      // 2022.11.27王昕卓修改接口
      let {result} = await request({
        url:
            "http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/algorithm/getyichangtimestamp/" +
            "br" +
            this.selectedPatientObj.brID +
            "cgm",
        method: "get",
      });
      // console.log(result)
      // let result = await Getyichangtimestamp(param);
      let timestap = result;
      let dates = new Date();
      let tempTime =
          dates.getFullYear() +
          "-" +
          (dates.getMonth() + 1 > 9
              ? dates.getMonth() + 1
              : "0" + (dates.getMonth() + 1));

      if (timestap) {
        // let sdtime1 = new Date(timestap)
        // sdtime1 = new Date(sdtime1)
        // let startTime = new Date(sdtime1).setHours(new Date(sdtime1).getHours() - this
        // 	.val) //小时
        // startTime = new Date(startTime)
        // startTime = this.dateFormat(startTime)

        let sdtime1 = new Date();
        if (timestap.indexOf("/") >= 0) {
          let dataInfoList = timestap.split(" ")[0].split("/");
          let timeInfo = timestap.split(" ")[1];
          let dataInfo =
              dataInfoList[2] +
              "-" +
              dataInfoList[1] +
              "-" +
              dataInfoList[0] +
              " " +
              timeInfo;
          sdtime1 = new Date(dataInfo);
        } else {
          sdtime1 = new Date(timestap);
        }

        let startTime = new Date(sdtime1).setHours(
            new Date(sdtime1).getHours() - this.val
        ); //小时

        this.begintime = this.dateFormat(startTime);
        this.endtime = this.dateFormat(sdtime1);

        const calendar = this.$refs.calendar;
        await calendar.move({
          month: sdtime1.getMonth() + 1,
          year: sdtime1.getFullYear(),
        });
        tempTime =
            sdtime1.getFullYear() +
            "-" +
            (sdtime1.getMonth() + 1 > 9
                ? sdtime1.getMonth() + 1
                : "0" + (sdtime1.getMonth() + 1));
      } else {
        this.begintime = 0;
        this.endtime = 0;
      }

      //更新日历可用日期
      let param2 = {
        // "Id": this.selectedPatientObj.patientId,
        Id: "br" + this.selectedPatientObj.brID + "cgm",
        Time: tempTime,
      };

      // 2022.11.28王昕卓修改接口
      const {data} = await request({
        url: "http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/syscontrolassess/getbrcgmsjbmy",
        method: "post",
        data: {
          brID: this.selectedPatientObj.brID,
          time: tempTime,
        },
      });
      let result2 = data;
      // console.log(result2)

      // let result2 = await GetPatientUserCGMTimeData(param2)
      this.disabledDates = result2.res;

      this.dynamicChart(this.val);
    },
    timestap_alert(originaltime) {
      let date = "";
      if (originaltime.indexOf("/") >= 0) {
        let dataInfoList = originaltime.split(" ")[0].split("/");
        let timeInfo = originaltime.split(" ")[1];
        let dataInfo =
            dataInfoList[2] +
            "-" +
            dataInfoList[1] +
            "-" +
            dataInfoList[0] +
            " " +
            timeInfo;
        date = new Date(dataInfo);
      } else {
        date = new Date(originaltime);
      }

      var Y = date.getFullYear() + "-";
      var M =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) + "-";
      var D =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
          (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
          ""; //date.getMinutes() + ':';
      var s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      return h + m;
    },
    timestap_choose(originaltime) {
      if (originaltime) {
        const date = new Date(originaltime);
        var Y = date.getFullYear() + "-";
        var M =
            (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "-";
        var D =
            (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
        var h =
            (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
            ":";
        var m =
            (date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes()) + ""; //date.getMinutes() + ':';
        var s =
            date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

        return Y + M + D + h + m;
      } else {
        return "--:--:--";
      }
    },
    dateFormat(originaltime) {
      const date = new Date(originaltime);
      var Y = date.getFullYear() + "-";
      var M =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) + "-";
      var D2 =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "T";
      var h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
          (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
          ":"; //date.getMinutes() + ':';
      var s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      return Y + M + D2 + h + m + s;
    },
    timestap_ageCac(originaltime) {
      const date1 = new Date(originaltime);
      const date2 = new Date();
      var Y1 = date1.getFullYear();
      var M1 = date1.getMonth() + 1;
      var Y2 = date2.getFullYear();
      var M2 = date2.getMonth() + 1;

      return Y2 - Y1;
    },
    timestap_bcCac(originaltime) {
      const date1 = new Date(originaltime);
      const date2 = new Date();
      var Y1 = date1.getFullYear();
      var M1 = date1.getMonth() + 1;
      var Y2 = date2.getFullYear();
      var M2 = date2.getMonth() + 1;
      var y = Y2 - Y1;
      var m = M2 - M1;
      if (m < 0) {
        m = 12 + m;
        y--;
        if (y === 0) {
          return m + "个月";
        }
        return y + "年" + m + "个月";
      } else {
        if (y === 0) {
          if (m === 0) {
            return "不足一个月";
          }
          return m + "个月";
        } else {
          if (m === 0) {
            return y + "年";
          }
          return y + "年" + m + "个月";
        }
      }
    },
    showTime() {
      this.myVar02 = "";
      // this.myVar02 = setInterval(()=>{
      const date = new Date();
      var Y = date.getFullYear() + "-";
      var M =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) + "-";
      var D =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var D2 =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "T";
      var h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
          (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
          ":"; //date.getMinutes() + ':';
      var s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      this.date = Y + M + D + h + m + s;
      // },1000)
    },

    htmltoPdf() {
      let Width = document.querySelector("#chec").offsetWidth;
      let Height = document.querySelector("#chec").offsetHeight;
      let offsetTop = document.querySelector("#chec").offsetTop;
      var heightStyle = this.$refs.checc.offsetHeight;
      alert(heightStyle + "-" + Height);
    },
    getPdf() {
      var title = "chart";
      html2canvas(document.querySelector("#chec"), {
        allowTaint: true,
      }).then(function (canvas) {
        let win_in =
            document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
        let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）

        // if (win_out > win_in) {
        //   // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
        //   abs = (win_out - win_in) / 2    // 获得滚动条宽度的一半
        //   // console.log(a, '新abs');
        // }
        let contentWidth = canvas.width * 2;
        let contentHeight = canvas.height * 2;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 595.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        let PDF = new JsPDF("", "pt", "a4");
        // if (leftHeight < pageHeight) {
        PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        // } else {
        //   while (leftHeight > 0) {
        //     PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        //     leftHeight -= pageHeight
        //     position -= 841.89
        //     if (leftHeight > 0) {
        //       PDF.addPage()
        //     }
        //   }
        // }
        PDF.save(title + ".pdf");
      });
    },
    pdfClick() {
      // console.log(this.selectedPatientObj.brID)
      this.handlebackstate();
      this.handlerembrID(this.selectedPatientObj.brID);

      // const params = {
      // 	ID: this.selectedPatientObj.patientId,
      // 	name: this.brName,
      // 	nl: this.csnyr,
      // 	sex: this.brXb,
      // 	lx: this.type,
      // 	bc: this.qzrq,
      // 	dataQuality: this.dataQuality,
      // 	suggestion: this.suggestion,
      // 	date: this.date,
      // 	ysName: this.ysName,
      // 	ysID: this.ysID,
      // 	begintime: this.begintime,
      // 	endtime: this.endtime,
      // }

      const params = {
        TWOname: this.TWOname,
        TWOid: this.TWOid,
        TWOgender: this.TWOgender,
        TWOage: this.TWOage,
        TWOtype: this.TWOtype,
        startday2: this.startday2,
        currentTime: this.currentTime,
        ID: this.selectedPatientObj.brID,
        xm: this.userNameParase,
        nl: this.selectedPatientObj.nl,
        xb: this.selectedPatientObj.xb,
        lx: this.selectedPatientObj.hblx,
        bc: this.qzrq,
        dataQuality: this.dataQuality,
        suggestion: this.suggestion,
        date: this.date,
        ysName: this.dockerObj.name,
        ysID: this.dockerObj.userId,
        begintime: getFromateTime(this.begintime, true),
        endtime: getFromateTime(this.endtime, true),
        HypoCount: this.HypoCount,
        TBR: this.TBR * 100,
        TIR: this.TIR * 100,
        TAR: this.TAR * 100,
        GMI: this.GMI,
        MSG: this.MSG,
        CV: this.CV * 100,
        SD: this.SD,

        dayHypoCount: this.dayHypoCount,
        dayTBR: this.dayTBR * 100,
        dayTIR: this.dayTIR * 100,
        dayTAR: this.dayTAR * 100,
        dayGMI: this.dayGMI,
        dayMSG: this.dayMSG,
        dayCV: this.dayCV * 100,
        daySD: this.daySD,

        nightHypoCount: this.nightHypoCount,
        nightTBR: this.nightTBR * 100,
        nightTIR: this.nightTIR * 100,
        nightTAR: this.nightTAR * 100,
        nightGMI: this.nightGMI,
        nightMSG: this.nightMSG,
        nightCV: this.nightCV * 100,
        nightSD: this.nightSD,

        minute: this.minute,
      };

      this.$router.push({
        path: "/chart/pdf2",
        query: {
          params,
        },
      });
      // this.$route.query.params.name
    },
    pdfClick2() {
      // console.log(this.selectedPatientObj.brID)
      this.handlebackstate();
      this.handlerembrID(this.selectedPatientObj.brID);
      const params = {
        TWOname: this.TWOname,
        TWOid: this.TWOid,
        TWOgender: this.TWOgender,
        TWOage: this.TWOage,
        TWOtype: this.TWOtype,
        startday2: this.startday2,

        currentTime: this.currentTime,
        ID: this.selectedPatientObj.brID,
        xm: this.userNameParase,
        nl: this.selectedPatientObj.nl,
        xb: this.selectedPatientObj.xb,
        lx: this.selectedPatientObj.hblx,
        bc: this.qzrq,
        dataQuality: this.dataQuality,
        suggestion: this.suggestion,
        date: this.date,
        ysName: this.dockerObj.name,
        ysID: this.dockerObj.userId,
        begintime: getFromateTime(this.begintime, true),
        endtime: getFromateTime(this.endtime, true),
        HypoCount: this.HypoCount,
        TBR: this.TBR * 100,
        TIR: this.TIR * 100,
        TAR: this.TAR * 100,
        GMI: this.GMI,
        MSG: this.MSG,
        CV: this.CV * 100,
        SD: this.SD,

        dayHypoCount: this.dayHypoCount,
        dayTBR: this.dayTBR * 100,
        dayTIR: this.dayTIR * 100,
        dayTAR: this.dayTAR * 100,
        dayGMI: this.dayGMI,
        dayMSG: this.dayMSG,
        dayCV: this.dayCV * 100,
        daySD: this.daySD,

        nightHypoCount: this.nightHypoCount,
        nightTBR: this.nightTBR * 100,
        nightTIR: this.nightTIR * 100,
        nightTAR: this.nightTAR * 100,
        nightGMI: this.nightGMI,
        nightMSG: this.nightMSG,
        nightCV: this.nightCV * 100,
        nightSD: this.nightSD,
        minute: this.minute,
      };

      this.$router.push({
        path: "/chart/pdf3",
        query: {
          params,
        },
      });
    },


    downloadPDF(ele, pdfName) {
      ele = document.querySelector("#chec");
      let eleW = ele.offsetWidth; // 获得该容器的宽
      let eleH = ele.offsetHeight; // 获得该容器的高
      let eleOffsetTop = ele.offsetTop; // 获得该容器到文档顶部的距离
      let eleOffsetLeft = ele.offsetLeft; // 获得该容器到文档最左的距离

      var canvas = document.createElement("canvas");
      var abs = 0;

      let win_in =
          document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
      let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）

      if (win_out > win_in) {
        // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
        abs = (win_out - win_in) / 2; // 获得滚动条宽度的一半
        // console.log(a, '新abs');
      }
      canvas.width = eleW * 2; // 将画布宽&&高放大两倍
      canvas.height = eleH * 2;

      var context = canvas.getContext("2d");
      context.scale(2, 2);
      context.translate(-eleOffsetLeft - abs, -eleOffsetTop);
      // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
      // translate的时候，要把这个差值去掉

      // html2canvas(element).then( (canvas)=>{ //报错
      // html2canvas(element[0]).then( (canvas)=>{
      html2canvas(ele, {
        dpi: 300,
        // allowTaint: true,  //允许 canvas 污染， allowTaint参数要去掉，否则是无法通过toDataURL导出canvas数据的
        useCORS: true, //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
      }).then((canvas) => {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        //一页pdf显示html页面生成的canvas高度;
        var pageHeight = (contentWidth / 592.28) * 841.89;
        //未生成pdf的html页面高度
        var leftHeight = contentHeight;
        //页面偏移
        var position = 0;
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        var imgWidth = 595.28;
        var imgHeight = (595.28 / contentWidth) * contentHeight;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new JsPDF("", "pt", "a4");
        //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        //当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          //在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
          pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
          // pdf.addImage(pageData, 'JPEG', 20, 40, imgWidth, imgHeight);
        } else {
          // 分页
          while (leftHeight > 0) {
            pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            //避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage();
            }
          }
        }
        //可动态生成
        pdf.save(pdfName);
      });
    },
    handleWindowPrint() {
      //浏览器方式打印
      //ele 为  css ID选择器
      //fileName    生成的PDF文件名
      //去除页面不必要的 head 标签内  内容， 避免影响打印页 ， title 为保存为 pdf 的文件时的 文件名
      document.head.innerHTML =
          '<meta charset="utf-8">\n' +
          " <title> " +
          "fileName" +
          "</title>\n" +
          ' <link rel="shortcut icon" href="http://192.168.29.50:8081/favicon.ico" type="image/x-icon" />\n' +
          ' <meta name="format-detection" content="telephone=no">\n' +
          ' <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">\n' +
          ' <meta name="viewport" content="width=device-width,initial-scale=1.0">\n' +
          ' <link rel="stylesheet" href="./static/css/contract.css"/>'; //生成pdf的外部css样式

      //要打印的 内容 html
      document.body.innerHTML = document.querySelector("chec").outerHTML;

      // window.print();

      //转异步 等待dom元素渲染（样式）完毕在打印
      setTimeout(() => {
        //打印
        window.print();
        //刷新页面
        window.location.reload();
      }, 200);
    },
    layuiRender() {
      layui.use("laydate", function () {
        var laydate = layui.laydate;
        var ins22 = laydate.render({
          elem: "#test-limit1",
          min: "2020-01-02",
          max: "2020-01-04",
          ready: function () {
            ins22.hint("日期可选值设定在 <br> 2020-01-02 到 2020-01-04");
          },
        });
        var ins21 = laydate.render({
          elem: "#test-limit2",
          min: "2020-01-02",
          max: "2020-01-04",
          ready: function () {
            ins22.hint("日期可选值设定在 <br> 2020-01-02 到 2020-01-04");
          },
        });
        laydate.render({
          elem: "#test-limit3",
          type: "time",
          min: "00:00:00",
          max: "23:59:59",
          btns: ["clear", "confirm"],
        });
        laydate.render({
          elem: "#test-limit4",
          type: "time",
          min: "00:00:00",
          max: "23:59:59",
          btns: ["clear", "confirm"],
        });
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.myVar01);
    clearInterval(this.myVar02);
    next();
  },
};
</script>

<style scoped="scoped" lang="less">
#chec {
  margin-top: 7vh;

  .cgmCheckBox {
    display: flex;
    width: 84vw;
    margin-left: 14vw;
    height: 54vw;
    position: relative; /* 相对定位 */
    z-index: 10; /* 低于表格的堆叠顺序 */

    .cgmCheckBoxLeft {
      flex: 5;
      // background-color: red; method
      margin-top: 1vh;
      height: 92vh;
      width: 56vw;

      .echarts1 {
        width: 100%;
        // 2023.1.9王昕卓修改高度
        // height: 13vh;
        height: 88%;
        padding: 0 0px 0px;
        background-color: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;
      }

      .echarts2 {
        width: 100%;
        // 2023.1.9王昕卓修改高度
        // height: 13vh;
        height: 75%;
        padding: 0 0px 0px;
        background-color: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;
      }

      .personalMessageBanner {
        width: 100%;
        // 2023.1.9王昕卓修改高度
        // height: 13vh;
        height: 7vh;
        padding: 0 30px 23px;
        background-color: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;

        .personalMessageItem {
          display: inline-block;
          margin-right: 50px;
          padding-top: 2vh;
          font-size: 1.1rem;
          font-weight: bold;
          color: #504da6;

          .itemTitle {
            font-weight: normal;
            margin-left: -20px;
            color: #7b7b7b;
          }
        }
      }

      .cgmChartBanner {
        position: relative;
        width: 100%;
        // height: 480px;
        height: 53vh;
        // margin: 30px 0;
        margin-top: 1vh;
        background-color: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;

        .timeSelected {
          position: absolute;
          top: 34px;
          right: 124px;
          z-index: 1;

          span {
            display: inline-block;
            width: 62px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #504ca5;
            cursor: pointer;

            &:hover {
              border: 1px solid #ececf2;
              border-radius: 8px;
              box-sizing: border-box;
            }
          }

          .atclick {
            border: 1px solid #ececf2;
            border-radius: 8px;
            box-sizing: border-box;
          }
        }

        .checkcharts {
          width: 100%;
          // height: 460px;
          height: 100%;
        }
      }

      .cgmResult {
        display: flex;
        width: 100%;
        // 2023.1.9王昕卓修改高度
        // height: 23vh;
        height: 21rem;
        margin-top: 1vh;

        .dataQualityBanner {
          position: relative;
          flex: 4;
          height: 425px;
          padding: 19px 30px;
          background-color: #ffffff;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          box-sizing: border-box;

          .dataQualityTitle {
            font-size: 1rem;
            font-weight: bold;
            color: #707070;
            margin-left: 40px;
          }

          .line-container {
            position: relative;
            margin-top: 30px;
            /* 调整横线和数值之间的垂直间距 */
          }

          .line {
            border-top: 2px solid #000;
            /* 设置横线的样式 */
            margin-top: 10px;
            margin-left: 140px;
          }

          .hengline {
            border-top: 2px solid #000;
            /* 设置横线的样式 */
            margin-top: 10px;
            margin-left: 140px;
          }

          .hengline1 {
            border-top: 2px solid #000;
            /* 设置横线的样式 */
            margin-top: -20px;
            margin-left: 240px;
          }

          .hengline2 {
            border-top: 2px solid #000;
            /* 设置横线的样式 */
            margin-top: 100px;
            margin-left: 140px;
          }

          .hengline3 {
            border-top: 2px solid #000;
            /* 设置横线的样式 */
            margin-top: 125px;
            margin-left: 140px;
          }

          .hengline4 {
            border-top: 2px solid #000;
            /* 设置横线的样式 */
            margin-top: 40px;
            margin-left: 140px;
          }

          .shuline {
            border-top: 2px solid #000;
            /* 设置横线的样式 */
            margin-top: 40px;
            margin-left: 140px;
          }

          .shuline1 {
            border-left: 2px solid #000;
            /* 设置竖线的样式 */
            height: 44px;
            /* 设置竖线的默认高度，可以根据需要调整 */
            margin-top: -44px;
            margin-left: 240px;
          }

          .shuline2 {
            border-left: 2px solid #000;
            /* 设置竖线的样式 */
            height: 44px;
            /* 设置竖线的默认高度，可以根据需要调整 */
            margin-top: -44px;
            margin-left: 240px;
          }

          .value {
            position: absolute;
            margin-top: -20px;
            /* 调整数值的垂直位置，使其在横线上方 */
            left: 50%;
            margin-left: 20px;
            /* 将数值置于横线正中间 */
            transform: translateX(-110%);
            /* 调整数值的水平位置，使其在横线正中间 */
          }

          .value1 {
            position: absolute;
            margin-top: -20px;
            margin-left: 20px;
            /* 调整数值的垂直位置，使其在横线上方 */
            left: 50%;
            /* 将数值置于横线正中间 */
            transform: translateX(-115%);
            /* 调整数值的水平位置，使其在横线正中间 */
          }

          .value2 {
            position: absolute;
            margin-top: -20px;
            margin-left: 150px;
            /* 调整数值的垂直位置，使其在横线上方 */
            left: 50%;
            /* 将数值置于横线正中间 */
            transform: translateX(-110%);
            /* 调整数值的水平位置，使其在横线正中间 */
          }

          .value3 {
            position: absolute;
            margin-top: -20px;
            margin-left: 100px;
            /* 调整数值的垂直位置，使其在横线上方 */
            left: 24%;
            /* 将数值置于横线正中间 */
            transform: translateX(-70%);
            /* 调整数值的水平位置，使其在横线正中间 */
          }

          .value4 {
            position: absolute;
            margin-top: -20px;
            margin-left: 100px;
            /* 调整数值的垂直位置，使其在横线上方 */
            left: 24%;
            /* 将数值置于横线正中间 */
            transform: translateX(-90%);
            /* 调整数值的水平位置，使其在横线正中间 */
          }

          .value5 {
            position: absolute;
            margin-top: -20px;
            margin-left: 100px;
            /* 调整数值的垂直位置，使其在横线上方 */
            left: 24%;
            /* 将数值置于横线正中间 */
            transform: translateX(-80%);
            /* 调整数值的水平位置，使其在横线正中间 */
          }

          .value250 {
            position: absolute;
            margin-top: -255px;
            margin-left: -70px;
            /* 调整数值的垂直位置，使其在横线上方 */
            left: 24%;
            /* 将数值置于横线正中间 */
          }

          .value180 {
            position: absolute;
            margin-top: -220px;
            margin-left: -70px;
            /* 调整数值的垂直位置，使其在横线上方 */
            left: 24%;
            /* 将数值置于横线正中间 */
          }

          .value1801 {
            position: absolute;
            margin-top: 353px;
            margin-left: -45px;
            /* 调整数值的垂直位置，使其在横线上方 */
            left: 24%;
            /* 将数值置于横线正中间 */
          }

          .value70 {
            position: absolute;
            margin-top: 437px;
            margin-left: -40px;
            /* 调整数值的垂直位置，使其在横线上方 */
            left: 24%;
            /* 将数值置于横线正中间 */
          }

          .wenzi {
            position: absolute;
            margin-top: 400px;
            margin-left: -100px;
            /* 调整数值的垂直位置，使其在横线上方 */
            left: 24%;
            /* 将数值置于横线正中间 */
          }

          .dataQualityContent {
            margin-top: 1vh;
            font-size: 14px;
            color: #333333;

            span {
              display: block;
              font-size: 20px;
              font-weight: bold;
              color: #504da6;
            }
          }

          .dataQualityChart {
            // width: 2rem;
            // height: 2rem;
            // width: 100px;
            // height: 100px;
            // background-color: pink;
            position: absolute;
            bottom: 18vh;
            right: 1vw;
            // transform: translateY(-50%);
          }

          .tableBox {
            margin-top: 20px;

            border-radius: 10px;
            overflow: hidden;

            .dataQualityAssessTable {
              width: 100%;
              border: 1px solid #c8c7f7;
              text-align: center;
              font-size: 0.8rem;
              margin-top: 50px;
            }
          }
        }

        .cgmUseAdviceBanner {
          flex: 4;
          height: 425px;
          margin: 0 1vw;
          padding: 19px 26px;
          background-color: #ffffff;
          // border: 1px solid #504DA6;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          box-sizing: border-box;

          .cgmUseAdviceTitle {
            font-size: 1rem;
            font-weight: bold;
            color: #707070;
          }

          .cgmUseAdviceContent {
            margin-top: 1vh;
            font-size: 14px;
            font-weight: bold;
            color: #504da6;
          }
        }

        /*					.dataQualityAssessBanner {
					  flex: 4;
					  // height: 275px;
  
					  padding: 19px 19px;
					  background-color: #FFFFFF;
					  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
					  border-radius: 10px;
					  box-sizing: border-box;
  
					  .dataQualityAssessTitle {
						font-size: 1.2rem;
						font-weight: bold;
						color: #707070;
					  }
  
  
					}*/
      }
      .container2 {
        display: flex; /* 使用 Flexbox 布局 */
        align-items: center; /* 垂直居中对齐内容 */
        margin-top: 2%; /* 顶部边距 */
        margin-left: 50px; /* 左边距 */
      }
      .color-block2 {
        width: 50px; /* 色块宽度 */
        height: 10px; /* 色块高度 */
        background-color: rgb(208,0,255); /* 色块背景颜色 */
        margin-right: 10px; /* 色块和文本之间的间距 */
        opacity: 0.6
      }
      .color-block3 {
        width: 10px; /* 圆的直径 */
        height: 10px; /* 圆的直径 */
        background-color: red; /* 色块背景颜色 */
        margin-right: 10px; /* 色块和文本之间的间距 */
        opacity: 0.6; /* 透明度 */
        border-radius: 50%; /* 使色块变成圆形 */
        margin-left: 20px; /* 向右移动 20px */
      }

      .text2 {
        font-size: 12px; /* 文字大小 */
        color: black; /* 文字颜色 */
      }
      .newContainer {
        margin-top: 450px;
        margin-left: -907px;
        padding: 15px;
        background-color: #f0f0f0;
        border-radius: 8px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        width: 100vw;
        height: 1000px;
      }
    }

    .cgmCheckBoxRight {
      flex: 2;
      margin-top: 1vh;
      height: 93vh;
      // margin-left: 22px;
      margin-left: 1vw;
      width: 28vw;

      .patientSelectBanner {
        display: flex;
        align-items: center;
        width: 90%;
        // height: 121px;
        // 2023.1.9王昕卓修改高度
        // height: 13vh;
        height: 7vh;
        padding: 0 30px;
        background-color: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;

        .patientSelectTitle {
          display: inline-block;
          font-size: 1.1rem;
          color: #707070;
        }

        .patientSelectcontent {
          width: 56%;
          height: 42px;
          margin: 0 0 0 10px;
          padding-left: 8px;
          background: #f5f5fa;
          border: 1px solid #504da6;
          border-radius: 4px;
          color: #8181a5;
          font-weight: normal;
        }
      }

      .dataQueryBanner {
        position: relative;
        width: 90%;
        // height: 480px;
        // 2023.1.9王昕卓修改高度
        // height: 65vh;
        height: 33.4rem;
        // margin: 30px 0;
        margin-top: 1vh;
        background-color: #ffffff;
        box-shadow: 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.1);
        border-radius: 0.625rem;
        box-sizing: border-box;

        .dataQueryTitle {
          // margin-bottom: 44px;
          padding: 1.25rem 1.875rem;
          // padding: 5px 30px;
          font-size: 1.2rem;
          font-weight: bold;
          color: #707070;
        }

        .dataQueryCalendar {
          width: 100%;

          border: none;
        }

        .buttonList {
          position: absolute;
          top: 1.5625rem;
          // top: 5px;
          right: 1.875rem;

          .buttonItem {
            padding: 0.25rem 0.4375rem;
            background: rgba(245, 245, 250, 0.4);
            border: 0.0625rem solid #504da6;
            border-radius: 0.25rem;
            color: #8181a5;
            cursor: pointer;

            &:last-child {
              margin-left: 0.75rem;
              background: #504da6;
              color: #ffffff;
            }
          }
        }

        .dataQueryTitleButton {
          position: absolute;
          right: 0;
          bottom: 0.6875rem;
          width: 3.125rem;
          height: 1.875rem;
          margin-left: 50%;
          transform: translateX(-50%);
          cursor: pointer;
          z-index: 1;
        }
      }

      .doctorMessageBanner {
        position: relative;
        width: 90%;
        // height: 275px;
        // 2023.1.9王昕卓修改高度
        // height: 23vh;
        height: 21rem;
        margin-top: 1vh;
        padding: 19px 21px;
        background-color: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;

        .checkButtonBox {
          position: absolute;
          top: 19px;
          right: 10px;

          .checkButton {
            padding: 4px 7px;
            background: rgba(245, 245, 250, 0.4);
            border: 1px solid #504da6;
            border-radius: 4px;
            color: #8181a5;
            cursor: pointer;

            &:first-child {
              margin-right: 12px;
              background: #504da6;
              color: #ffffff;
            }
          }
        }

        .doctorMessageTitle {
          font-size: 1rem;
          font-weight: bold;
          color: #707070;

          span {
            display: block;
            margin: 0.5vh 0 30px;
            font-size: 0.9rem;
            font-weight: normal;
            color: #333333;
          }
        }
      }
    }

    .cgmCheckBoxBOTTOM {
      display: flex;
      // background-color: red; method
      margin-top: 200px;
      height: 500px;
      width: 500px;
    }
  }
}
</style>